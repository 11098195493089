import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { Address, GetAddressResponse, getAddressResponseDecoder } from "../addresses/interfaces";
import { fetchDefaultConfigs } from "../config";
import { StringJsendResponse, stringJsendResponseDecoder } from "../interfaces";
import { CartPaymentMethod, PaymentMethodResponse, paymentMethodResponseDecoder } from "../neosaccount/interfaces";
import {
  AddItemToCartResponse,
  AddProductToCartResponse,
  CartProducts,
  OrderItem,
  PayCartResponse,
  UpdateSslResponse,
  UpdateWordpressResponse,
  addItemToCartResponseDecoder,
  addProductToCartResponseDecoder,
  payCartResponseDecoder,
  updateSslResponseDecoder,
  updateWordpressResponseDecoder,
} from "./interfaces";

export async function addItemToCart(domain: string, type: OrderItem["type"]): Promise<AddItemToCartResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/add/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      domain,
      type,
    }),
  });

  return addItemToCartResponseDecoder.verify(await response.json());
}

export async function removeItemFromCart(domain: string): Promise<StringJsendResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/remove/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      domain,
    }),
  });

  return stringJsendResponseDecoder.verify(await response.json());
}

export async function updateDomainYears(item: OrderItem): Promise<AddItemToCartResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/update_domain_years/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      item,
    }),
  });

  return addItemToCartResponseDecoder.verify(await response.json());
}

export async function switchDomainAutoRenew(item: OrderItem): Promise<AddItemToCartResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/switch_domain_autorenew/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      item,
    }),
  });

  return addItemToCartResponseDecoder.verify(await response.json());
}

export async function switchDomainPrivacy(item: OrderItem): Promise<AddItemToCartResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/switch_domain_privacy/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      item,
    }),
  });

  return addItemToCartResponseDecoder.verify(await response.json());
}

export async function switchPremiumDNS(item: OrderItem): Promise<AddItemToCartResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/switch_premium_dns/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      item,
    }),
  });

  return addItemToCartResponseDecoder.verify(await response.json());
}

export async function switchDomainProductAutoRenew(
  item: OrderItem,
  product: "domainPrivacy" | "premiumDNS",
): Promise<AddItemToCartResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/switch_domain_product_autorenew/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      item,
      product,
    }),
  });

  return addItemToCartResponseDecoder.verify(await response.json());
}

export async function clearCart(): Promise<StringJsendResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/clear/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
  });

  return stringJsendResponseDecoder.verify(await response.json());
}

export async function addProductToDomains(product: CartProducts, domains: string[]): Promise<AddProductToCartResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/add_product/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      product,
      domains,
    }),
  });

  return addProductToCartResponseDecoder.verify(await response.json());
}

export async function removeProduct(product: CartProducts, domain: string): Promise<StringJsendResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/remove_product/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      product,
      domain,
    }),
  });

  return stringJsendResponseDecoder.verify(await response.json());
}

export async function updateWordpress(domain: string, value: string): Promise<UpdateWordpressResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/update_wordpress/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      domain,
      value,
    }),
  });

  return updateWordpressResponseDecoder.verify(await response.json());
}

export async function updateSsl(domain: string, value: string): Promise<UpdateSslResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/update_ssl/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      domain,
      value,
    }),
  });

  return updateSslResponseDecoder.verify(await response.json());
}

export async function updatePaymentMethod(item: CartPaymentMethod): Promise<PaymentMethodResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/update_payment_method/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      item,
    }),
  });

  return paymentMethodResponseDecoder.verify(await response.json());
}

export async function updateBillingAddress(item: Address): Promise<GetAddressResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/update_billing_address/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      item,
    }),
  });

  return getAddressResponseDecoder.verify(await response.json());
}

export async function payCard(): Promise<PayCartResponse> {
  const response = await enhancedFetch(`${API_URL}/cart/pay/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({}),
  });

  return payCartResponseDecoder.verify(await response.json());
}
