import { Stack, Typography } from "@mui/material";
import { Address } from "Services/api/addresses/interfaces";

export interface ContactAddressProps {
  contact: Address | null;
  variant?: "body1" | "body2";
}

export function ContactAddress({ contact, variant = "body1" }: ContactAddressProps) {
  if (!contact) return null;
  const { firstName, lastName, address1, address2, city, stateProvince, zipPostalCode, country, phoneNumber, email } =
    contact;

  return (
    <Stack>
      <Typography variant={variant}>
        {firstName} {lastName}
      </Typography>
      <Typography variant={variant}>
        {address1} {address2 && `, ${address2}`}
      </Typography>
      <Typography variant={variant}>
        {city}; {stateProvince} {zipPostalCode}
      </Typography>
      <Typography variant={variant}>
        {country} tel: {phoneNumber}
      </Typography>
      <Typography variant={variant}>{email}</Typography>
    </Stack>
  );
}
