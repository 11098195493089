import { HOT_SERVER, NODE_ENV } from "Utils/envVariables";
import { createRoot, hydrateRoot } from "react-dom/client";
import BrowserApp from "./components/App/BrowserApp";
import { setupViewHeight } from "./setup/setupVh";

const container = document.getElementById("root");

if (HOT_SERVER && NODE_ENV === "development") {
  const root = createRoot(container as HTMLElement);
  root.render(<BrowserApp />);

  if (module.hot) {
    module.hot.accept("./components/App/BrowserApp", () => {
      void import("./components/App/BrowserApp").then(({ default: BrowserApp }) => {
        root.render(<BrowserApp />);
      });
    });
  }
} else hydrateRoot(container as HTMLElement, <BrowserApp />);

setupViewHeight();
