import { AddShoppingCart, Close, ExpandMore, KeyboardArrowDown, LockRounded, Public } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  SvgIcon,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MoneyFormat, useBackdropContext } from "@nc/neoscloud-common-react";
import { useMutation } from "@tanstack/react-query";
import { useCartContext, useMainDataContext } from "Containers/MainContainer/MainProvider";
import {
  addProductToDomains,
  clearCart,
  removeItemFromCart,
  removeProduct,
  switchDomainAutoRenew,
  switchDomainPrivacy,
  switchDomainProductAutoRenew,
  switchPremiumDNS,
  updateDomainYears,
  updateSsl,
  updateWordpress,
} from "Services/api/cart/cart";
import {
  AddProductResponse,
  Cart,
  CartDomainItem,
  CartItem,
  CartProducts,
  DefaultCartProduct,
  ProductsPrices,
  SslValues,
  WordpressValues,
} from "Services/api/cart/interfaces";
import DomainSearch from "Shared/DomainSearch/DomainSearch";
import { useSnackbar } from "notistack";
import { Dispatch, Fragment, ReactNode, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useJsendHandler } from "../../../hooks/useJsendHandler";
import { useJsendMutation } from "../../../hooks/useJsendMutation";

function Cart() {
  const [{ state }] = useMainDataContext();
  const [cart] = useCartContext();

  return state === "init" ? (
    <></>
  ) : (
    <Container maxWidth="xl">
      <Grid container spacing={4}>
        <Grid item xs={12} md={9}>
          <CartItems />
        </Grid>
        <Grid item xs={12} md={3}>
          <Subtotal subtotal={cart.subtotal} count={cart.count} />
        </Grid>
      </Grid>
    </Container>
  );
}

const ItemDivider = styled(Divider)({
  marginTop: "16px",
  marginBottom: "16px",
  borderBottomWidth: "medium",
});

function CartItems() {
  const [cart, setCart] = useCartContext();
  const productsPrices = cart.data.productsPrices;
  const [, setBackdrop] = useBackdropContext();

  const { mutateAsync: onClear } = useJsendMutation({
    jsendFn: () => {
      setBackdrop({
        open: true,
        msg: "Clearing cart...",
      });
      return clearCart();
    },
    onSuccess: async () => {
      await setCart({});
      setBackdrop({ open: false, msg: "" });
    },
    onError: () => setBackdrop({ open: false, msg: "" }),
  });

  return cart.count === 0 ? (
    <EmptyCart />
  ) : (
    <Fragment>
      <Button variant="outlined" startIcon={<Close />} sx={{ textTransform: "none" }} onClick={() => void onClear()}>
        Clear all items
      </Button>
      <ItemDivider />
      {cart.items.map((item) => (
        <ItemRow key={item.name} item={item} productsPrices={productsPrices} />
      ))}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Improve Your Site
      </Typography>
      <ProductItem
        product="wordpress"
        title="WordPress Hosting"
        subheader="Managed WordPress Hosting"
        description="Launch and host WordPress on your site."
        price={
          <Fragment>
            <MoneyFormat amount={productsPrices.wordpress.monthly} />
            /mo
          </Fragment>
        }
        Icon={Public}
      />
      <ProductItem
        product="ssl"
        title="SSL"
        subheader="Site security made simple"
        description="Secure your new domain with an SSL certificate."
        price={
          <Fragment>
            <MoneyFormat amount={productsPrices.ssl["1"]} />
            /yr
          </Fragment>
        }
        Icon={LockRounded}
      />
      <ItemDivider />
      <ContinueButton />
    </Fragment>
  );
}

interface ItemRowProps {
  item: CartDomainItem;
  productsPrices: ProductsPrices;
}

function ItemRow({ item, productsPrices }: ItemRowProps) {
  const [cart, setCart] = useCartContext();
  const {
    name,
    data: {
      type,
      years,
      autoRenew,
      price,
      iCannFee,
      products: { domainPrivacy, premiumDNS, ssl, wordpress },
    },
  } = item;
  const [isDomainAutoRenewLoading, setIsDomainAutoRenewLoading] = useState<boolean>(false);
  const [productLoading, setProductLoading] = useState<"" | "wordpress" | "ssl">("");

  const { responseFn: wpResFn, errorFn } = useJsendHandler<WordpressValues>({
    onSuccess: async (data) => {
      cart.data.items[name].products.wordpress = data;
      await setCart({ ...cart.data.items });
      setProductLoading("");
    },
    onError: () => {
      setProductLoading("");
    },
  });

  const { mutateAsync: OnWordPressUpdate } = useMutation({
    mutationFn: async (value: string) => {
      setProductLoading("wordpress");
      await wpResFn(updateWordpress(name, value));
    },
    onError: errorFn,
  });

  const { responseFn: sslResFn } = useJsendHandler<SslValues>({
    onSuccess: async (data) => {
      cart.data.items[name].products.ssl = data;
      await setCart({ ...cart.data.items });
      setProductLoading("");
    },
  });

  const { mutateAsync: OnSslUpdate } = useMutation({
    mutationFn: async (value: string) => {
      setProductLoading("ssl");
      await sslResFn(updateSsl(name, value));
    },
    onError: errorFn,
  });

  return (
    <Grid container alignItems="center" rowSpacing={2}>
      <ItemRowLayout
        type={type === "registration" ? "Domain Registration" : "Domain Renewal"}
        value={name}
        price={price}
        option1={<DomainYears name={name} years={years} />}
        option2={
          <DomainAutoRenew
            name={name}
            autoRenew={autoRenew}
            setIsDomainAutoRenewLoading={setIsDomainAutoRenewLoading}
          />
        }
        remove={<RemoveDomain name={name} />}
      />
      {iCannFee > 0 && <ICANNFee iCannFee={iCannFee} />}
      <Grid item xs={1} />
      <Grid item xs={12}>
        <Typography variant="subtitle2" sx={{ fontSize: "1rem", color: "#a0a1a3" }}>
          Privacy and Uptime protection
        </Typography>
      </Grid>
      <DomainPrivacy
        name={name}
        domainPrivacy={domainPrivacy}
        price={productsPrices.domainPrivacy}
        domainAutoRenew={autoRenew}
        isDomainAutoRenewLoading={isDomainAutoRenewLoading}
      />
      <PremiumDNS
        name={name}
        premiumDNS={premiumDNS}
        price={productsPrices.premiumDNS}
        domainAutoRenew={autoRenew}
        isDomainAutoRenewLoading={isDomainAutoRenewLoading}
      />

      {ssl && (
        <Fragment>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <ItemRowLayout
            type="SSL"
            price={productsPrices.ssl[ssl]}
            option1={
              <Select
                fullWidth
                value={ssl}
                onChange={(e) => void OnSslUpdate(e.target.value as SslValues)}
                SelectDisplayProps={{
                  "aria-label": `Select ${name} ssl subscription years`,
                }}
                disabled={productLoading == "ssl"}
              >
                <MenuItem value={1}>1 year</MenuItem>
                <MenuItem value={2}>2 years</MenuItem>
                <MenuItem value={3}>3 years</MenuItem>
                <MenuItem value={4}>4 years</MenuItem>
                <MenuItem value={5}>5 years</MenuItem>
              </Select>
            }
            remove={<RemoveProduct domain={name} product="ssl" />}
          />
        </Fragment>
      )}

      {wordpress && (
        <Fragment>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <ItemRowLayout
            type="WordPress"
            price={productsPrices.wordpress[wordpress]}
            option1={
              <Select
                fullWidth
                value={wordpress}
                onChange={(e) => void OnWordPressUpdate(e.target.value as WordpressValues)}
                SelectDisplayProps={{
                  "aria-label": `Select ${name} wordpress payment type`,
                }}
                disabled={productLoading == "wordpress"}
              >
                <MenuItem value={"monthly"}>Monthly</MenuItem>
                <MenuItem value={"yearly"}>Yearly</MenuItem>
              </Select>
            }
            remove={<RemoveProduct domain={name} product="wordpress" />}
          />
        </Fragment>
      )}

      <Grid item xs={12}>
        <ItemDivider />
      </Grid>
    </Grid>
  );
}

interface RemoveProductProps {
  domain: string;
  product: "ssl" | "wordpress";
}

function RemoveProduct({ domain, product }: RemoveProductProps) {
  const [cart, setCart] = useCartContext();
  const [, setBackdrop] = useBackdropContext();
  const { mutateAsync: onRemove } = useJsendMutation({
    jsendFn: () => {
      setBackdrop({
        open: true,
        msg: "Removing product from domain...",
      });
      return removeProduct(product, domain);
    },
    onSuccess: async () => {
      delete cart.data.items[domain]["products"][product];
      await setCart({ ...cart.data.items });
      setBackdrop({ open: false, msg: "" });
    },
    onError: () => setBackdrop({ open: false, msg: "" }),
  });

  return (
    <IconButton onClick={() => void onRemove()} aria-label={`remove ${product} from domain`}>
      <Close
        sx={({
          palette: {
            primary: { light },
          },
        }) => ({ color: light, stroke: light, strokeWidth: "1" })}
      />
    </IconButton>
  );
}

interface DomainYearsProps {
  name: string;
  years: number;
}

function DomainYears({ name, years }: DomainYearsProps) {
  const [cart, setCart] = useCartContext();
  const { responseFn, errorFn } = useJsendHandler<CartItem>({
    onSuccess: async (data) => await setCart({ ...cart.data.items, [name]: data }),
  });

  const { mutateAsync: onChange, isLoading } = useMutation({
    mutationFn: async (value: number) => {
      await responseFn(updateDomainYears(cart.getDomainItem(name).toJson({ years: value })));
    },
    onError: errorFn,
  });

  return (
    <Select
      fullWidth
      value={isLoading ? 0 : years}
      onChange={(e) => void onChange(e.target.value as number)}
      SelectDisplayProps={{
        "aria-label": `Select ${name} subscription years`,
      }}
      disabled={isLoading}
    >
      {isLoading && (
        <MenuItem value={0}>
          <CircularProgress size={15} /> Changing...
        </MenuItem>
      )}
      <MenuItem value={1}>1 year</MenuItem>
      <MenuItem value={2}>2 years</MenuItem>
      <MenuItem value={3}>3 years</MenuItem>
      <MenuItem value={4}>4 years</MenuItem>
      <MenuItem value={5}>5 years</MenuItem>
      <MenuItem value={6}>6 years</MenuItem>
      <MenuItem value={7}>7 years</MenuItem>
      <MenuItem value={8}>8 years</MenuItem>
      <MenuItem value={9}>9 years</MenuItem>
      <MenuItem value={10}>10 years</MenuItem>
    </Select>
  );
}

interface DomainAutoRenewProps {
  name: string;
  autoRenew: boolean;
  setIsDomainAutoRenewLoading: Dispatch<SetStateAction<boolean>>;
}

function DomainAutoRenew({ name, autoRenew, setIsDomainAutoRenewLoading }: DomainAutoRenewProps) {
  const [cart, setCart] = useCartContext();

  const { mutateAsync: onChange, isLoading } = useJsendMutation({
    jsendFn: () => {
      setIsDomainAutoRenewLoading(true);
      return switchDomainAutoRenew(cart.getDomainItem(name).toJson());
    },
    onSuccess: async (data) => {
      await setCart({ ...cart.data.items, [name]: data });
      setIsDomainAutoRenewLoading(false);
    },
    onError: () => {
      setIsDomainAutoRenewLoading(false);
    },
  });

  return (
    <FormControlLabel
      value="bottom"
      data-testid="auto-renew-domain"
      control={
        isLoading ? (
          <CircularProgress size={25} sx={{ mb: "8px", mt: "5px" }} />
        ) : (
          <Switch color="primary" checked={autoRenew} onChange={() => void onChange()} disabled={isLoading} />
        )
      }
      sx={{ width: "100%", m: 0 }}
      label="AUTO-RENEW"
      labelPlacement="bottom"
      componentsProps={{
        typography: {
          variant: "caption",
        },
      }}
    />
  );
}

interface RemoveDomainProps {
  name: string;
}

function RemoveDomain({ name }: RemoveDomainProps) {
  const [cart, setCart] = useCartContext();
  const [, setBackdrop] = useBackdropContext();

  const { mutateAsync: onRemove } = useJsendMutation({
    jsendFn: () => {
      setBackdrop({
        open: true,
        msg: "Removing item from cart...",
      });
      return removeItemFromCart(name);
    },
    onSuccess: async () => {
      delete cart.data.items[name];
      await setCart({ ...cart.data.items });
      setBackdrop({ open: false, msg: "" });
    },
    onError: () => setBackdrop({ open: false, msg: "" }),
  });

  return (
    <IconButton onClick={() => void onRemove()} aria-label={`remove domain ${name}`}>
      <Close
        sx={({
          palette: {
            primary: { light },
          },
        }) => ({ color: light, stroke: light, strokeWidth: "1" })}
      />
    </IconButton>
  );
}

interface ICANNFeeProps {
  iCannFee: number;
}

function ICANNFee({ iCannFee }: ICANNFeeProps) {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={9}>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                flexDirection: "row-reverse",
                minHeight: "0px !important",
                padding: 0,
                "& .MuiAccordionSummary-expandIconWrapper": {
                  mr: "5px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                },
              }}
            >
              <Typography variant="body2">ICANN fee</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pl: 0, pr: 0, textAlign: "justify" }}>
              <Typography variant="body2">
                The Internet Corporation for Assigned Names and Numbers ICANN charges a mandatory annual fee of $0.18
                for each year of domain registration, renewal or transfer. Where applicable, the ICANN fee will be added
                to the listed price for the domain at the time of purchase and itemized in your shopping cart.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            <MoneyFormat amount={iCannFee} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

interface DomainPrivacyProps {
  name: string;
  domainPrivacy: DefaultCartProduct;
  price: number;
  domainAutoRenew: boolean;
  isDomainAutoRenewLoading: boolean;
}

function DomainPrivacy({ name, domainPrivacy, price, domainAutoRenew, isDomainAutoRenewLoading }: DomainPrivacyProps) {
  const [cart, setCart] = useCartContext();

  const { mutateAsync: onEnabledChange, isLoading: isEnabledLoading } = useJsendMutation({
    jsendFn: () => {
      return switchDomainPrivacy(cart.getDomainItem(name).toJson());
    },
    onSuccess: async (data) => {
      await setCart({ ...cart.data.items, [name]: data });
    },
  });

  const { mutateAsync: onAutoRenewChange, isLoading: isAutoRenewLoading } = useJsendMutation({
    jsendFn: () => {
      return switchDomainProductAutoRenew(cart.getDomainItem(name).toJson(), "domainPrivacy");
    },
    onSuccess: async (data) => {
      await setCart({ ...cart.data.items, [name]: data });
    },
  });

  return (
    <CartProduct
      id="domain-privacy"
      type="Domain Privacy"
      value="1 year subscription"
      product={domainPrivacy}
      price={price}
      isEnabledLoading={isEnabledLoading}
      isAutoRenewLoading={isEnabledLoading || isAutoRenewLoading || isDomainAutoRenewLoading}
      disableAutoRenew={!domainAutoRenew || !domainPrivacy.enabled}
      onEnabledChange={() => void onEnabledChange()}
      onAutoRenewChange={() => void onAutoRenewChange()}
    />
  );
}

interface PremiumDNSProps {
  name: string;
  premiumDNS: DefaultCartProduct;
  price: number;
  domainAutoRenew: boolean;
  isDomainAutoRenewLoading: boolean;
}

function PremiumDNS({ name, premiumDNS, price, domainAutoRenew, isDomainAutoRenewLoading }: PremiumDNSProps) {
  const [cart, setCart] = useCartContext();

  const { mutateAsync: onEnabledChange, isLoading: isEnabledLoading } = useJsendMutation({
    jsendFn: () => {
      return switchPremiumDNS(cart.getDomainItem(name).toJson());
    },
    onSuccess: async (data) => {
      await setCart({ ...cart.data.items, [name]: data });
    },
  });

  const { mutateAsync: onAutoRenewChange, isLoading: isAutoRenewLoading } = useJsendMutation({
    jsendFn: () => {
      return switchDomainProductAutoRenew(cart.getDomainItem(name).toJson(), "premiumDNS");
    },
    onSuccess: async (data) => {
      await setCart({ ...cart.data.items, [name]: data });
    },
  });

  return (
    <CartProduct
      id="premium-dns"
      type="PremiumDNS"
      value=""
      product={premiumDNS}
      price={price}
      isEnabledLoading={isEnabledLoading}
      isAutoRenewLoading={isEnabledLoading || isAutoRenewLoading || isDomainAutoRenewLoading}
      disableAutoRenew={!domainAutoRenew || !premiumDNS.enabled}
      onEnabledChange={() => void onEnabledChange()}
      onAutoRenewChange={() => void onAutoRenewChange()}
    />
  );
}

interface CartProductProps {
  id: string;
  type: string;
  value: string;
  product: DefaultCartProduct;
  price: number;
  disableAutoRenew: boolean;
  isEnabledLoading: boolean;
  isAutoRenewLoading: boolean;
  onEnabledChange: () => void;
  onAutoRenewChange: () => void;
}

function CartProduct({
  id,
  type,
  value,
  product,
  price,
  disableAutoRenew,
  isEnabledLoading,
  isAutoRenewLoading,
  onEnabledChange,
  onAutoRenewChange,
}: CartProductProps) {
  return (
    <ItemRowLayout
      type={type}
      value={value}
      price={price}
      enabled={product.enabled}
      option1={
        <FormControlLabel
          value="bottom"
          data-testid={`enable-${id}`}
          control={
            isEnabledLoading ? (
              <CircularProgress size={25} sx={{ mb: "8px", mt: "5px" }} />
            ) : (
              <Switch color="primary" checked={product.enabled} onChange={onEnabledChange} />
            )
          }
          sx={{ width: "100%", m: 0 }}
          label="ENABLE"
          labelPlacement="bottom"
          componentsProps={{
            typography: {
              variant: "caption",
            },
          }}
        />
      }
      option2={
        <FormControlLabel
          value="bottom"
          data-testid={`auto-renew-${id}`}
          control={
            isAutoRenewLoading ? (
              <CircularProgress size={25} sx={{ mb: "8px", mt: "5px" }} />
            ) : (
              <Switch
                color="primary"
                checked={product.autoRenew}
                disabled={disableAutoRenew}
                onChange={onAutoRenewChange}
              />
            )
          }
          sx={{ width: "100%", m: 0 }}
          label="AUTO-RENEW"
          labelPlacement="bottom"
          componentsProps={{
            typography: {
              variant: "caption",
            },
          }}
        />
      }
    />
  );
}

interface ProductItemProps {
  product: CartProducts;
  title: string;
  subheader: string;
  description: string;
  price: ReactNode;
  Icon: typeof SvgIcon;
}

function ProductItem({ product, title, subheader, description, price, Icon }: ProductItemProps) {
  const [cart, setCart] = useCartContext();
  const domains = cart.domains;
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectItems, setSelectItems] = useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectState, setSelectState] = useState<{ [key: string]: boolean }>({});
  const { responseFn, errorFn } = useJsendHandler<AddProductResponse>({
    onSuccess: async (data) => {
      for (const { domain, product, value } of data) {
        if (domain in cart.data.items) {
          if (product === "ssl") cart.data.items[domain].products.ssl = value;
          else if (product === "wordpress") cart.data.items[domain].products.wordpress = value;
        }
      }

      await setCart({ ...cart.data.items });
      enqueueSnackbar(`Product${data.length > 1 ? "s" : ""} added successfully`, { variant: "success" });
    },
    notifyOnSuccess: false,
  });

  const { mutateAsync: onAdd, isLoading } = useMutation({
    mutationFn: async (names: string[]) => {
      await responseFn(addProductToDomains(product, names));
    },
    onError: errorFn,
  });

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
      </Grid>
      <Grid item xs={12} sm={2} lg={1}>
        <Box sx={{ width: "fit-content", margin: "auto" }}>
          <Icon
            sx={(theme) => ({
              boxSizing: "content-box",
              fill: theme.palette.common.white,
              width: "36px",
              height: "36px",
              p: "14px",
              borderRadius: "100%",
              backgroundColor: theme.palette.primary.main,
            })}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={7} lg={9}>
        <Card component="aside" elevation={0}>
          <CardHeader title={title} subheader={subheader} />
          <CardContent sx={{ pt: 0 }}>
            <Typography>{description}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>
        {domains.length === 1 ? (
          <Button
            id={`add-product-button-${product}`}
            variant="contained"
            startIcon={<AddShoppingCart />}
            fullWidth
            sx={{ textTransform: "lowercase" }}
            onClick={() => void onAdd(domains)}
            disabled={isLoading}
          >
            {price}
          </Button>
        ) : (
          <Fragment>
            <Button
              id={`add-product-button-${product}`}
              aria-controls={open ? `add-product-menu-${product}` : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              fullWidth
              sx={{ textTransform: "lowercase" }}
              endIcon={<KeyboardArrowDown />}
              onClick={handleClick}
              disabled={isLoading}
            >
              {price}
            </Button>
            <Menu
              id={`add-product-menu-${product}`}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": `add-product-button-${product}`,
              }}
              sx={{ width: "100%" }}
            >
              <MenuItem
                onClick={() => {
                  void onAdd(domains);
                  handleClose();
                }}
              >
                Add to all items
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectItems(true);
                  handleClose();
                }}
              >
                Add to some items
              </MenuItem>
            </Menu>
          </Fragment>
        )}
      </Grid>
      {selectItems && (
        <Grid item xs={12}>
          <Stack
            component={Paper}
            variant="outlined"
            spacing={2}
            sx={(theme) => ({
              p: "2em 1em",
              [theme.breakpoints.up("sm")]: {
                p: "2em 1.5em",
              },
            })}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Add to these domains:</FormLabel>
              <FormGroup aria-label="domain" row>
                {domains.map((domain) => (
                  <FormControlLabel
                    key={domain}
                    value={domain}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          const newSelectState = { ...selectState };
                          newSelectState[domain] = e.target.checked;
                          setSelectState(newSelectState);
                        }}
                      />
                    }
                    label={domain}
                    labelPlacement="end"
                  />
                ))}
              </FormGroup>
            </FormControl>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => {
                  const selectedDomains = Object.keys(selectState).filter((domain) => selectState[domain]);
                  void onAdd(selectedDomains);
                  setSelectItems(false);
                  setSelectState({});
                }}
              >
                Add to selected items
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setSelectItems(false);
                  setSelectState({});
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}

function EmptyCart() {
  return (
    <Paper sx={{ p: "25px" }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Your shopping cart is currently empty.
      </Typography>
      <DomainSearch placeholder="Search for a domain" />
    </Paper>
  );
}

interface SubtotalProps {
  subtotal: number;
  count: number;
}

function Subtotal({ subtotal, count }: SubtotalProps) {
  return (
    <Card component="aside" variant="outlined">
      <CardHeader title="Your Subtotal" />
      <CardContent>
        <Typography>
          Subtotal <MoneyFormat amount={subtotal} />
        </Typography>
      </CardContent>
      {count > 0 && (
        <CardActions sx={{ padding: "16px" }}>
          <ContinueButton fullWidth />
        </CardActions>
      )}
    </Card>
  );
}

function ContinueButton(props: ButtonProps) {
  const navigate = useNavigate();
  const [{ state }] = useMainDataContext();
  return (
    <Button
      variant="contained"
      color="error"
      onClick={() => {
        if (state === "logged") navigate("/checkout/payment");
        else navigate("/login");
      }}
      {...props}
    >
      Confirm Order
    </Button>
  );
}

interface ItemRowLayoutProps {
  type: string;
  value?: string;
  option1: ReactNode;
  option2?: ReactNode;
  enabled?: boolean;
  price: string | number;
  remove?: ReactNode;
}

function ItemRowLayout({
  type,
  value = "",
  option1,
  option2 = null,
  enabled = true,
  price,
  remove,
}: ItemRowLayoutProps) {
  const disabledPriceStyle = enabled ? {} : { fontWeight: "400", textDecoration: "line-through", color: "#a0a1a3" };

  return (
    <Fragment>
      <Grid item xs={11} sm={5}>
        <Typography variant="h6" textTransform="capitalize">
          {type}
        </Typography>
        <Typography>{value}</Typography>
      </Grid>
      <Grid
        item
        xs={1}
        sx={(theme) => ({
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
        })}
      >
        {remove}
      </Grid>
      <Grid item xs={6} sm={2} alignItems="center">
        {option1}
      </Grid>
      <Grid item xs={6} sm={2} alignItems="center">
        {option2}
      </Grid>
      <Grid item xs={12} sm={2}>
        <Typography sx={{ textAlign: "center", fontWeight: "bold", ...disabledPriceStyle }}>
          <MoneyFormat amount={price} />
        </Typography>
      </Grid>
      <Grid
        item
        xs={1}
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        })}
      >
        {remove}
      </Grid>
    </Fragment>
  );
}

export default Cart;
