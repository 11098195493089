import { ShoppingCart } from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import logo from "Public/NeoscloudLogo.png";
import { logout } from "Services/api/auth/auth";
import { MouseEvent, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useJsendMutation } from "../../../hooks/useJsendMutation";
import { useCartContext, useMainDataContext } from "./MainProvider";

const rightLink: SxProps<Theme> = {
  fontSize: 18,
  fontWeight: 700,
  color: "common.white",
  ml: 3,
  textTransform: "capitalize",
  "&:hover": {
    bgcolor: "#424242",
  },
};

function Header() {
  const [{ state }] = useMainDataContext();
  const [cart] = useCartContext();

  return (
    <div>
      <AppBar position="fixed" sx={{ alignItems: "center", boxShadow: "none", zIndex: 1500 }}>
        <Container
          component={Toolbar}
          maxWidth="xl"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: "10px",
            pb: "10px",
            width: "100%",
          }}
          style={{ minHeight: "82px" }}
        >
          <RouterLink to="/">
            <Box src={logo} width="100px" component="img" alt="NeosCloud logo" />
          </RouterLink>
          <Stack direction="row" spacing={3} justifyContent="flex-end" flex={1}>
            <Badge badgeContent={cart.count} color="secondary">
              <Button to="/cart" sx={rightLink} component={RouterLink} endIcon={<ShoppingCart />}>
                Cart
              </Button>
            </Badge>

            {state === "init" ? (
              <Button>Loading...</Button>
            ) : state === "logged" ? (
              <UserMenu />
            ) : (
              <Button to="/login" sx={rightLink} component={RouterLink}>
                Login
              </Button>
            )}
          </Stack>
        </Container>
      </AppBar>
      <Toolbar style={{ minHeight: "82px" }} />
    </div>
  );
}

function UserMenu() {
  const [{ state }, setMain] = useMainDataContext();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { mutateAsync: onLogOut } = useJsendMutation({
    jsendFn: () => logout(),
    onSuccess: async () => {
      await setMain({
        user: {
          username: "",
          first_name: "",
          last_name: "",
          email: "",
        },
        state: "notLogged",
        cart: {
          items: {},
          productsPrices: {
            domainPrivacy: 0,
            premiumDNS: 0,
            ssl: { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 },
            wordpress: { monthly: 0, yearly: 0 },
          },
        },
      });
      navigate("/login");
    },
    successMessage: "Logout success",
  });

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open account menu">
        <Button onClick={handleOpenUserMenu} sx={rightLink}>
          Account
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: "45px", zIndex: 2000 }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={() => {
            handleCloseUserMenu();
            const to = state === "logged" ? "/account/dashboard" : "/login";
            navigate(to);
          }}
        >
          <Typography textAlign="center" data-testid="account-link">
            Dashboard
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseUserMenu();
            navigate("/account/domains");
          }}
        >
          <Typography textAlign="center" data-testid="billing-link">
            Domains
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseUserMenu();
            navigate("/account/profile");
          }}
        >
          <Typography textAlign="center" data-testid="billing-link">
            Profile
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            void onLogOut();
            handleCloseUserMenu();
          }}
        >
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default Header;
