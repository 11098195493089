import { Stack, Table, TableBody, TableCell, TableRow, Typography, Button } from "@mui/material";
import { useMainDataContext } from "Containers/MainContainer/MainProvider";
import { NEOSACCOUNT_URL } from "Utils/envVariables";

function Profile() {
  const [
    {
      user: { first_name, last_name, username, email },
    },
  ] = useMainDataContext();

  return (
    <Stack spacing={3}>
      <Typography variant="h5">Personal Info</Typography>
      <Typography variant="h6">Basic Info</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>
              {first_name} {last_name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Username</strong>
            </TableCell>
            <TableCell>{username}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Email</strong>
            </TableCell>
            <TableCell>{email}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button variant="contained" href={`${NEOSACCOUNT_URL}/account/`} sx={{ width: "fit-content" }}>
        Edit
      </Button>
    </Stack>
  );
}

export default Profile;
