import { Edit, ShoppingCart } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormikCheckbox, FormikForm, FormikSelect, FormikSubmitButton, MoneyFormat } from "@nc/neoscloud-common-react";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Stripe } from "@stripe/stripe-js/types/stripe-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCartContext, useMainDataContext } from "Containers/MainContainer/MainProvider";
import { getAddress, getAddresses } from "Services/api/addresses/addresses";
import { Address, ListAddress } from "Services/api/addresses/interfaces";
import { updateBillingAddress, updatePaymentMethod } from "Services/api/cart/cart";
import { CartDomainItem } from "Services/api/cart/interfaces";
import { CartPaymentMethod, PaymentMethods, SavedCard } from "Services/api/neosaccount/interfaces";
import { getPaymentMethods } from "Services/api/neosaccount/neosaccount";
import { ContactAddress } from "Shared/ContactAddress/ContactAddress";
import { ContactFormFields } from "Shared/ContactFormFields/ContactFormFields";
import { Query } from "Shared/Query/Query";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { STRIPE_KEY } from "Utils/envVariables";
import { Formik, useField } from "formik";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const stripePromise: Promise<Stripe | null> = loadStripe(STRIPE_KEY);

function Payment() {
  const [{ state }] = useMainDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (state === "notLogged") navigate("/login");
  }, [state, navigate]);

  if (state === "init") return null;

  return (
    <Elements stripe={stripePromise}>
      <Main />
    </Elements>
  );
}

function Main() {
  const [cart, setCart] = useCartContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const elements = useElements();

  return (
    <Container maxWidth="xl">
      <Formik
        initialValues={{
          address: cart.billingAddress,
          paymentMethod: cart.paymentMethod,
          rememberPayment: (cart.paymentMethod && !cart.paymentMethod.saved && cart.paymentMethod.remember) || false,
        }}
        onSubmit={async (
          {
            address,
            paymentMethod,
            rememberPayment,
          }: { address: Address; paymentMethod: CartPaymentMethod; rememberPayment: boolean },
          { setSubmitting },
        ) => {
          if (!paymentMethod) {
            enqueueSnackbar("Please select a payment method", { variant: "error" });
            return;
          }

          // istanbul ignore next
          if (!paymentMethod.saved) {
            if (elements === null || stripe === null) return;
            const cardEl = elements.getElement("card");
            if (!cardEl) return;

            const { token, error } = await stripe.createToken(cardEl);

            if (!token || !token.card) {
              if (error) {
                if (error.message) enqueueSnackbar(error.message, { variant: "error" });
                console.error(error);
                return;
              }
              enqueueSnackbar("An error occured with the payment method", { variant: "error" });
              return;
            }
            const card = token.card;

            paymentMethod = {
              saved: false,
              brand: card.brand,
              last4: card.last4,
              exp_month: card.exp_month,
              exp_year: card.exp_year,
              token: token.id,
              remember: rememberPayment,
            };
          }

          await updatePaymentMethod(paymentMethod);

          if (address.id === 0) {
            queryClient.setQueryData<Address>([getAddress.name + "-" + 0], () => address);
          } else {
            const cachedAddress = queryClient.getQueryData<Address>([getAddress.name + "-" + address.id]);
            if (cachedAddress) address = cachedAddress;
          }

          await updateBillingAddress(address);

          await setCart({ ...cart.data.items }, address, paymentMethod);
          setSubmitting(false);
          navigate("/checkout/review");
        }}
      >
        <FormikForm>
          <Grid container columnGap={4} justifyContent="center">
            <Grid item xs={12} lg={8}>
              <PaymentInfo />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CartAside />
            </Grid>
          </Grid>
        </FormikForm>
      </Formik>
    </Container>
  );
}

const ItemDivider = styled(Divider)({
  marginTop: "16px",
  marginBottom: "16px",
  borderBottomWidth: "medium",
});

function PaymentInfo() {
  const result = useQuery({
    queryKey: [getPaymentMethods.name],
    queryFn: async () => {
      const { status, data } = await getPaymentMethods();
      if (status !== "success") throw "Error fetching payment methods!";

      return data;
    },
    cacheTime: 0,
  });

  return (
    <Stack spacing={4}>
      <Query result={result} onSuccess={(data) => <PaymentMethodSection paymentMethods={data} />} />

      <BillingAddress />
      <ItemDivider />
      <ContinueButton />
    </Stack>
  );
}

interface PaymentMethodProps {
  paymentMethods: PaymentMethods;
}

function PaymentMethodSection({ paymentMethods }: PaymentMethodProps) {
  const [paymentMethod, setPaymentMethod] = useState<string>(
    String(paymentMethods.find((method) => method.default)?.id || "add"),
  );
  const { enqueueSnackbar } = useSnackbar();
  const [, , { setValue }] = useField<CartPaymentMethod>("paymentMethod");

  useEffect(() => {
    const defaultPaymentMethod = paymentMethods.find((method) => method.default);
    if (defaultPaymentMethod) {
      void setValue(defaultPaymentMethod);
    }
  }, [paymentMethods, setValue]);

  const { mutateAsync: onChange, isLoading } = useMutation({
    mutationFn: async (value: string) => {
      if (value != "loading" && value != "add") {
        const record = paymentMethods.find((method) => method.id === value) as SavedCard;
        await setValue(record);
      } else if (value === "add") {
        await setValue({ token: "", saved: false, brand: "", last4: "", exp_month: 0, exp_year: 0, remember: false });
      }
    },
    onError: () => {
      enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
    },
  });

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Stack direction="row" spacing={1}>
            Payment Method
          </Stack>
        }
      />
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={5}>
            <Typography variant="h6" textTransform="capitalize">
              Select Payment Method
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Select
              fullWidth
              value={paymentMethod}
              onChange={(event) => {
                setPaymentMethod(event.target.value);
                void onChange(event.target.value);
              }}
              disabled={isLoading}
            >
              <MenuItem value="add">Add new card</MenuItem>
              {paymentMethods.map((method) => (
                <MenuItem key={method.id} value={method.id}>
                  {method.brand} ending in {method.last4}
                </MenuItem>
              ))}
              {isLoading && (
                <MenuItem value="loading">
                  <CircularProgress size={15} /> Changing...
                </MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={12} sx={{ display: paymentMethod === "add" ? "initial" : "none" }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={5}>
                <Typography variant="h6" textTransform="capitalize">
                  Card
                </Typography>
              </Grid>

              <Grid item xs={12} sm={5}>
                <CardElement />
              </Grid>
              <Grid item xs={12}>
                <FormikCheckbox name="rememberPayment" label="Remember card for later use" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function BillingAddress() {
  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Stack direction="row" spacing={1}>
            Billing Address
          </Stack>
        }
      />
      <CardContent>
        <AddressForm />
      </CardContent>
    </Card>
  );
}

function AddressForm() {
  const [, , { setValue }] = useField<number>("address.id");
  const result = useQuery({
    queryKey: [getAddresses.name],
    queryFn: async () => {
      const { status, data } = await getAddresses();
      if (status === "fail") return [];

      for (const address of data) {
        if (address.isDefault) {
          void setValue(address.id);
          break;
        }
      }

      if (data.length > 0) void setValue(data[0].id);
      else void setValue(0);

      return data;
    },
    cacheTime: 0,
  });

  return (
    <Query
      result={result}
      OnLoading={() => <>Loading addresses...</>}
      onSuccess={(addresses) => <AddressBody addresses={addresses} />}
    />
  );
}

interface AddressBodyProps {
  addresses: ListAddress[];
}

function AddressBody({ addresses }: AddressBodyProps) {
  const [{ value }] = useField<number>("address.id");
  const selectedAddressResult = useQuery({
    queryKey: [getAddress.name + "-" + value],
    queryFn: async () => {
      if (value === 0) return null;
      const { status, data } = await getAddress(value, true);
      if (status === "fail") throw new Error("Address not found");

      return data;
    },
    cacheTime: 0,
  });

  return (
    <Stack spacing={2}>
      <FormikSelect id="id" name="address.id" label="Select billing Address" required fullWidth>
        {addresses.map((address) => (
          <MenuItem key={address.id} value={address.id}>
            {address.name}
          </MenuItem>
        ))}
        <MenuItem value={0}>Add new address</MenuItem>
      </FormikSelect>
      {value === 0 ? (
        <ContactFormFields />
      ) : (
        <Query
          result={selectedAddressResult}
          OnLoading={() => <>Loading address...</>}
          onSuccess={(address) => <ContactAddress contact={address} />}
        />
      )}
    </Stack>
  );
}

function ContinueButton(props: ButtonProps) {
  return (
    <FormikSubmitButton variant="contained" color="error" {...props}>
      Continue
    </FormikSubmitButton>
  );
}

function CartAside() {
  const navigate = useNavigate();
  const [{ items, subtotal }] = useCartContext();

  return (
    <Card component="aside" variant="outlined">
      <CardHeader
        title={
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <ShoppingCart /> <span>Your Cart</span>
            </Stack>
            <Button variant="text" startIcon={<Edit />} onClick={() => navigate("/cart")}>
              Edit
            </Button>
          </Stack>
        }
        sx={{ pb: "0" }}
      />
      <CardContent sx={{ pt: "0", pb: "0" }}>
        <Table>
          <TableBody>
            {items.map((item) => (
              <CartItem key={item.name} item={item} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle1">Subtotal</Typography>
                  <Typography variant="body2">
                    <MoneyFormat amount={subtotal} />
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>

      <CardActions sx={{ padding: "16px" }}>
        <ContinueButton fullWidth />
      </CardActions>
    </Card>
  );
}

interface CartItemProps {
  item: CartDomainItem;
}

function CartItem({ item }: CartItemProps) {
  const {
    name: domain,
    products: { premiumDNS, ssl, wordpress },
  } = item;
  const [
    {
      productsPrices: { premiumDNS: premiumDNSPrice, ssl: sslPrices, wordpress: wordpressPrices },
    },
  ] = useCartContext();

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <Stack spacing={1}>
            <Typography variant="subtitle1">{domain}</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                {(item.years > 1 ? `${item.years} years ` : `${item.years} year `) + "registration"}
              </Typography>
              <Typography variant="body2">
                <MoneyFormat amount={item.price} />
              </Typography>
            </Stack>
            {item.iCannFee > 0 && (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2">ICANN fee</Typography>
                <Typography variant="body2">
                  <MoneyFormat amount={item.iCannFee} />
                </Typography>
              </Stack>
            )}
          </Stack>
        </TableCell>
      </TableRow>
      {premiumDNS.enabled && (
        <TableRow>
          <TableCell>
            <Stack spacing={1}>
              <Typography variant="subtitle1">PremiumDNS</Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2">1 year subscription</Typography>
                <Typography variant="body2">
                  <MoneyFormat amount={premiumDNSPrice} />
                </Typography>
              </Stack>
            </Stack>
          </TableCell>
        </TableRow>
      )}
      {ssl && (
        <TableRow>
          <TableCell>
            <Stack spacing={1}>
              <Typography variant="subtitle1">SSL</Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2">{`${ssl} ${Number(ssl) > 1 ? "years" : "year"}`}</Typography>
                <Typography variant="body2">
                  <MoneyFormat amount={sslPrices[ssl]} />
                </Typography>
              </Stack>
            </Stack>
          </TableCell>
        </TableRow>
      )}
      {wordpress && (
        <TableRow>
          <TableCell>
            <Stack spacing={1}>
              <Typography variant="subtitle1">WordPress</Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2">{`${wordpress} plan`}</Typography>
                <Typography variant="body2">
                  <MoneyFormat amount={wordpressPrices[wordpress]} />
                </Typography>
              </Stack>
            </Stack>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
}

export default Payment;
