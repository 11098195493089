import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { fetchDefaultConfigs } from "../config";
import {
  AddCardResponse,
  PaymentMethodsResponse,
  addCardResponseDecoder,
  paymentMethodsResponseDecoder,
} from "./interfaces";

export async function addNewCard(tokenId: string): Promise<AddCardResponse> {
  const response = await enhancedFetch(`${API_URL}/neosaccount/add_card/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      token_id: tokenId,
    }),
  });

  return addCardResponseDecoder.verify(await response.json());
}

export async function getPaymentMethods(): Promise<PaymentMethodsResponse> {
  const response = await enhancedFetch(`${API_URL}/neosaccount/cards/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return paymentMethodsResponseDecoder.verify(await response.json());
}
