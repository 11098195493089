import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MoneyFormat } from "@nc/neoscloud-common-react";
import { useMutation } from "@tanstack/react-query";
import { useCartContext, useMainDataContext } from "Containers/MainContainer/MainProvider";
import { payCard } from "Services/api/cart/cart";
import { ContactAddress } from "Shared/ContactAddress/ContactAddress";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { showSnackbarErrorMessage } from "Utils/snackbar";
import { enqueueSnackbar } from "notistack";
import { Fragment, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Review() {
  const [{ state }] = useMainDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (state === "notLogged") navigate("/login");
  }, [state, navigate]);

  if (state === "init") return null;

  return (
    <Container maxWidth="xl">
      <Grid container spacing={4}>
        <Grid item xs={12} md={9}>
          <OrderReview />
          <PaymentDetails />
          <Button variant="contained" color="error" onClick={() => navigate("/checkout/summary")}>
            Pay Now
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Subtotal />
        </Grid>
      </Grid>
    </Container>
  );
}

const ItemDivider = styled(Divider)({
  marginTop: "16px",
  marginBottom: "16px",
  borderBottomWidth: "medium",
});

function OrderReview() {
  const navigate = useNavigate();
  const [cart] = useCartContext();

  const {
    items,
    subtotal,
    productsPrices: {
      premiumDNS: premiumDNSPrice,
      domainPrivacy: domainPrivacyPrice,
      ssl: sslPrices,
      wordpress: wordpressPrices,
    },
  } = cart;

  return (
    <Fragment>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" component="h1" gutterBottom>
          Order Review
        </Typography>
        <Button variant="text" startIcon={<Edit />} onClick={() => navigate("/cart")}>
          Edit cart
        </Button>
      </Stack>
      {items.map(({ name, price, years, iCannFee, products: { premiumDNS, domainPrivacy, ssl, wordpress } }) => (
        <Grid container key={name} alignItems="center" rowSpacing={2}>
          <Grid item xs={12}>
            <ItemDivider />
          </Grid>
          <ItemRowLayout
            type="Domain Registration"
            value={name}
            price={price}
            option={<Typography>{(years > 1 ? `${years} years ` : `${years} year `) + "registration"}</Typography>}
          />
          {iCannFee > 0 && <ICANNFee iCannFee={iCannFee} />}
          {premiumDNS.enabled && (
            <Fragment>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <ItemRowLayout
                type="PremiumDNS"
                value={`for ${name}`}
                price={premiumDNSPrice}
                option={<Typography>1 year subscription</Typography>}
              />
            </Fragment>
          )}
          {domainPrivacy.enabled && (
            <Fragment>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <ItemRowLayout
                type="Free Domain Privacy"
                value={`for ${name}`}
                price={domainPrivacyPrice}
                option={<Typography>1 year subscription</Typography>}
              />
            </Fragment>
          )}
          {ssl && (
            <Fragment>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <ItemRowLayout
                type="SSL"
                value={`for ${name}`}
                price={sslPrices[ssl]}
                option={<Typography>{`${ssl} ${Number(ssl) > 1 ? "years" : "year"}`}</Typography>}
              />
            </Fragment>
          )}
          {wordpress && (
            <Fragment>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <ItemRowLayout
                type="WordPress Hosting"
                value={`for ${name}`}
                price={wordpressPrices[wordpress]}
                option={<Typography>{`${wordpress} plan`}</Typography>}
              />
            </Fragment>
          )}
        </Grid>
      ))}
      <ItemDivider />
      <Grid container alignItems="center" rowSpacing={2}>
        <ItemRowLayout type="Subtotal" price={subtotal} option={null} />
      </Grid>
      <ItemDivider />
    </Fragment>
  );
}

function PaymentDetails() {
  const [{ billingAddress }] = useCartContext();
  const navigate = useNavigate();
  const [{ paymentMethod: method }] = useCartContext();

  if (!billingAddress || !method) return null;

  return (
    <Fragment>
      <Box height={80} />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" component="h1" gutterBottom>
          Payment Details
        </Typography>
        <Button variant="text" startIcon={<Edit />} onClick={() => navigate("/checkout/payment")}>
          Change
        </Button>
      </Stack>
      <ItemDivider />
      <Grid container>
        <Grid item xs={12} sm={5}>
          <Typography variant="h6" textTransform="capitalize">
            Payment Method
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Stack>
            <Typography>
              {method.brand} ending in {method.last4}
            </Typography>
            <Box height={16} />
            <Typography>Expiration Date</Typography>
            <Typography>
              {method.exp_month}/{method.exp_year}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <ItemDivider />
      <Grid container>
        <Grid item xs={12} sm={5}>
          <Typography variant="h6" textTransform="capitalize">
            Billing Address
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <ContactAddress contact={billingAddress} />
        </Grid>
      </Grid>

      <ItemDivider />
    </Fragment>
  );
}

function Subtotal() {
  const [{ subtotal }] = useCartContext();
  const navigate = useNavigate();

  const { mutateAsync: onChange, isLoading } = useMutation({
    mutationFn: async () => {
      const { status, data } = await payCard();
      if (status === "fail") {
        data.match({
          left: (err) => showSnackbarErrorMessage(enqueueSnackbar, err.message),
          right: showSnackbarErrorMessage(enqueueSnackbar),
        });
        return;
      }

      navigate("/checkout/summary");
    },
    onError: () => {
      enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
    },
  });

  return (
    <Card component="aside" variant="outlined">
      <CardHeader title="Your Subtotal" />
      <CardContent>
        <Typography>
          <MoneyFormat amount={subtotal} />
        </Typography>
      </CardContent>

      <CardActions sx={{ padding: "16px" }}>
        {isLoading && <CircularProgress size={15} />}{" "}
        <Button variant="contained" color="error" fullWidth onClick={() => void onChange()} disabled={isLoading}>
          Pay Now
        </Button>
      </CardActions>
    </Card>
  );
}

interface ItemRowLayoutProps {
  type: string;
  value?: string;
  option: ReactNode;
  price: string | number;
  remove?: ReactNode;
}

function ItemRowLayout({ type, value = "", option, price }: ItemRowLayoutProps) {
  return (
    <Fragment>
      <Grid item xs={12} sm={5}>
        <Typography variant="h6" textTransform="capitalize">
          {type}
        </Typography>
        <Typography>{value}</Typography>
      </Grid>
      <Grid item xs={12} sm={4} alignItems="center">
        {option}
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
          <MoneyFormat amount={price} />
        </Typography>
      </Grid>
    </Fragment>
  );
}

interface ICANNFeeProps {
  iCannFee: number;
}

function ICANNFee({ iCannFee }: ICANNFeeProps) {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="body2">ICANN fee</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            <MoneyFormat amount={iCannFee} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Review;
