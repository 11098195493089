import { jsendResponseDecoder } from "Utils/jsend";
import { DecoderType, constant, either, object, string } from "decoders";
import { cartDecoder } from "../cart/interfaces";

const userDecoder = object({
  username: string,
  first_name: string,
  last_name: string,
  email: string,
});

export type User = DecoderType<typeof userDecoder>;

export const mainDataDecoder = object({
  user: userDecoder,
  state: either(constant("init"), constant("fail"), constant("error"), constant("logged"), constant("notLogged")),
  cart: cartDecoder,
});

export type MainData = DecoderType<typeof mainDataDecoder>;

export const getCurrentUserResponseDecoder = jsendResponseDecoder(mainDataDecoder, string);

export type GetCurrentUserResponse = DecoderType<typeof getCurrentUserResponseDecoder>;
