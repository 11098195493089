import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { fetchDefaultConfigs } from "../config";
import {
  GetAddressResponse,
  GetAddressesResponse,
  getAddressResponseDecoder,
  getAddressesResponseDecoder,
} from "./interfaces";

export const getAddresses = async (): Promise<GetAddressesResponse> => {
  const response = await enhancedFetch(`${API_URL}/addresses`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return getAddressesResponseDecoder.verify(await response.json());
};

export const getAddress = async (id: number | string, save = false): Promise<GetAddressResponse> => {
  const response = await enhancedFetch(`${API_URL}/addresses/${id}/${save ? "?save=true" : ""}`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return getAddressResponseDecoder.verify(await response.json());
};
