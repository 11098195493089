import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Address } from "Services/api/addresses/interfaces";
import { ShoppingCart, cartAddressInitialValues, cartItemsDecoder } from "Services/api/cart/interfaces";
import { CartPaymentMethod } from "Services/api/neosaccount/interfaces";
import { MainData } from "Services/api/users/interfaces";
import { getCurrentUser } from "Services/api/users/users";
import { openDB } from "idb";
import { PropsWithChildren, createContext, useContext, useState } from "react";

const init: MainData = {
  user: {
    username: "",
    first_name: "",
    last_name: "",
    email: "",
  },
  state: "init",
  cart: {
    items: {},
    productsPrices: {
      domainPrivacy: 0,
      premiumDNS: 0,
      ssl: { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 },
      wordpress: { monthly: 0, yearly: 0 },
    },
  },
};

const MainDataContext = createContext<[MainData, (data: MainData) => Promise<void>]>([
  init,
  () => {
    return Promise.resolve();
  },
]);

export function useMainDataContext() {
  return useContext(MainDataContext);
}

const CartContext = createContext<
  [
    ShoppingCart,
    (items: MainData["cart"]["items"], billingAddress?: Address, paymentMethod?: CartPaymentMethod) => Promise<void>,
  ]
>([
  new ShoppingCart(init.cart),
  () => {
    return Promise.resolve();
  },
]);

export function useCartContext() {
  return useContext(CartContext);
}

async function getDb() {
  return await openDB("neosdomain-storage", 1, {
    upgrade(db) {
      db.createObjectStore("cart");
    },
  });
}

/* istanbul ignore next */
async function deleteDbItems() {
  try {
    const db = await getDb();
    await db.delete("cart", "items");
    db.close();
  } catch (error) {
    console.error(error);
  }
}

interface MainProviderProps extends PropsWithChildren {
  paymentMethod?: CartPaymentMethod;
  billingAddress?: Address;
}

export function MainProvider({ children, paymentMethod, billingAddress }: MainProviderProps) {
  const [main, setMain] = useState<MainData>(init);
  const shoppingCart = new ShoppingCart(init.cart);
  if (paymentMethod) shoppingCart.paymentMethod = paymentMethod;
  if (billingAddress) shoppingCart.billingAddress = billingAddress;
  const [cart, setCart] = useState<ShoppingCart>(shoppingCart);

  useQuery<MainData>({
    queryKey: [getCurrentUser.name],
    queryFn: async () => {
      const { status, data } = await getCurrentUser();
      if (status === "fail") {
        setMain({ ...init, state: "fail" });
        return init;
      }
      setMain(data);
      if (data.state === "notLogged") {
        try {
          const db = await getDb();
          const dbItems = (await db.get("cart", "items")) as unknown;
          db.close();

          const items = dbItems ? cartItemsDecoder.verify(dbItems) : undefined;
          if (items) data.cart.items = items;
        } catch (error) {
          await deleteDbItems();
          console.error(error);
        }
      }
      const shoppingCart = new ShoppingCart(data.cart);
      if (paymentMethod) shoppingCart.paymentMethod = paymentMethod;
      if (billingAddress) shoppingCart.billingAddress = billingAddress;
      setCart(shoppingCart);
      return data;
    },
    onError: () => {
      setMain({ ...init, state: "error" });
      return init;
    },
  });
  const queryClient = useQueryClient();

  return (
    <MainDataContext.Provider
      value={[
        main,
        async (data) => {
          setMain(data);
          setCart(new ShoppingCart(data.cart));
          queryClient.setQueryData<MainData>([getCurrentUser.name], data);

          if (data.state === "logged") await deleteDbItems();
          else if (data.state === "notLogged") {
            try {
              const db = await getDb();
              await db.put("cart", data.cart.items, "items");
              db.close();
            } catch (error) {
              console.error(error);
            }
          }
        },
      ]}
    >
      <CartContext.Provider
        value={[
          cart,
          async (items, billingAddress = cartAddressInitialValues, paymentMethod = null) => {
            const newCart = { ...cart.data, items };
            const newMain = { ...main, cart: newCart };
            setMain(newMain);
            const newShoppingCart = new ShoppingCart(newCart);
            newShoppingCart.paymentMethod = paymentMethod;
            newShoppingCart.billingAddress = billingAddress;
            setCart(newShoppingCart);
            queryClient.setQueryData<MainData>([getCurrentUser.name], newMain);

            /* istanbul ignore next */
            if (main.state === "notLogged") {
              try {
                const db = await getDb();
                await db.put("cart", items, "items");
                db.close();
              } catch (error) {
                console.error(error);
              }
            }
          },
        ]}
      >
        {children}
      </CartContext.Provider>
    </MainDataContext.Provider>
  );
}
