import { Grid, MenuItem } from "@mui/material";
import { FormikCheckbox, FormikSelect, FormikTextField } from "@nc/neoscloud-common-react";
import { useField } from "formik";
import { Fragment } from "react";

export function ContactFormFields() {
  return (
    <Fragment>
      <FormikTextField id="firstName" name="address.firstName" label="First Name" required fullWidth />
      <FormikTextField id="lastName" name="address.lastName" label="Last Name" required fullWidth />
      <CompanyFields />
      <FormikTextField id="address1" name="address.address1" label="Address Line 1" required fullWidth />
      <FormikTextField id="address2" name="address.address2" label="Address Line 2" fullWidth />
      <FormikTextField id="city" name="address.city" label="City" required fullWidth />
      <FormikTextField id="stateProvince" name="address.stateProvince" label="State/Province" required fullWidth />
      <FormikTextField id="zipPostalCode" name="address.zipPostalCode" label="ZIP/Postal Code" required fullWidth />
      <FormikSelect id="country" name="address.country" label="Country" required>
        <MenuItem value="US">United States</MenuItem>
        <MenuItem value="CA">Canada</MenuItem>
        <MenuItem value="MX">Mexico</MenuItem>
        <MenuItem value="UK">United Kingdom</MenuItem>
        <MenuItem value="DE">Germany</MenuItem>
        <MenuItem value="FR">France</MenuItem>
        <MenuItem value="ES">Spain</MenuItem>
        <MenuItem value="IT">Italy</MenuItem>
        <MenuItem value="JP">Japan</MenuItem>
        <MenuItem value="CN">China</MenuItem>
        <MenuItem value="IN">India</MenuItem>
        <MenuItem value="BR">Brazil</MenuItem>
        <MenuItem value="RU">Russia</MenuItem>
        <MenuItem value="AU">Australia</MenuItem>
        <MenuItem value="SE">Sweden</MenuItem>
        <MenuItem value="NL">Netherlands</MenuItem>
        <MenuItem value="TR">Turkey</MenuItem>
        <MenuItem value="CH">Switzerland</MenuItem>
        <MenuItem value="DO">Dominican Republic</MenuItem>
        <MenuItem value="ZA">South Africa</MenuItem>
        <MenuItem value="EG">Egypt</MenuItem>
      </FormikSelect>
      <PhoneFields />
      <FormikTextField id="email" name="address.email" label="Email Address" required fullWidth />
    </Fragment>
  );
}

function CompanyFields() {
  const [{ value: isCompanyDomain }] = useField<boolean>("isCompanyDomain");
  return (
    <Fragment>
      {isCompanyDomain && (
        <Fragment>
          <FormikTextField
            id="companyName"
            name="address.companyName"
            label="Company Name"
            required={isCompanyDomain}
            fullWidth
          />
          <FormikTextField
            id="jobTitle"
            name="address.jobTitle"
            label="Job Title"
            required={isCompanyDomain}
            fullWidth
          />
        </Fragment>
      )}
      <FormikCheckbox
        id="isCompanyDomain"
        name="address.isCompanyDomain"
        label="Domain is registered on behalf of a company"
      />
    </Fragment>
  );
}

function PhoneFields() {
  const [{ value: hasPhoneExt }] = useField<boolean>("hasPhoneExt");
  return (
    <Fragment>
      <Grid columnGap={2} rowGap={2} container>
        <Grid item xs={12}>
          <label htmlFor={"phoneNumber"}>Phone Number</label>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormikSelect id="areaCode" name="address.areaCode" required>
            <MenuItem value="+1">+1</MenuItem>
            <MenuItem value="+44">+44</MenuItem>
            <MenuItem value="+49">+49</MenuItem>
            <MenuItem value="+33">+33</MenuItem>
            <MenuItem value="+34">+34</MenuItem>
            <MenuItem value="+39">+39</MenuItem>
            <MenuItem value="+81">+81</MenuItem>
            <MenuItem value="+86">+86</MenuItem>
          </FormikSelect>
        </Grid>
        <Grid item xs={12} sm>
          <FormikTextField id="phoneNumber" name="address.phoneNumber" label="Phone Number" required fullWidth />
        </Grid>
        <Grid item xs={12} sm={hasPhoneExt ? 3 : 0}>
          {hasPhoneExt && (
            <FormikTextField id="phoneExt" name="address.phoneExt" label="Extension" fullWidth required={hasPhoneExt} />
          )}
        </Grid>
      </Grid>
      <FormikCheckbox id="hasPhoneExt" name="address.hasPhoneExt" label="Phone has extension" />
    </Fragment>
  );
}
