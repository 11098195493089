import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMainDataContext } from "Containers/MainContainer/MainProvider";
import DomainSearch from "Shared/DomainSearch/DomainSearch";
import dashboardData from "TestSchemas/dashboard_data.json";
import { Link as RouterLink } from "react-router-dom";

function Dashboard() {
  const [
    {
      user: { first_name, last_name },
    },
  ] = useMainDataContext();

  return (
    <Stack spacing={3}>
      <Typography variant="h5">
        Hello {first_name} {last_name}
      </Typography>
      <DomainSearch placeholder="Search for your next domain" />
      <Typography variant="subtitle1">Recently active in your account</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Domains</TableCell>
            <TableCell>Expiration</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboardData.map((row) => (
            <TableRow key={row.name}>
              <TableCell>
                <strong>{row.name}</strong>
              </TableCell>
              <TableCell>{row.expiration}</TableCell>
              <TableCell align="right">
                <Button variant="contained" color="primary" href={`/account/domains/${row.name}/`}>
                  Manage
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3}>
              <Button component={RouterLink} to="/account/domains" sx={{ p: "0" }}>
                View all domains
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Stack>
  );
}

export default Dashboard;
