import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { JsendResponse } from "Utils/jsend";
import { useSnackbar } from "notistack";

export interface JsendHandlerOptions<T, F = unknown, E = unknown> {
  jsendFn?: () => Promise<JsendResponse<T, F>>;
  onSuccess?: (data: T) => Promise<void>;
  successMessage?: string;
  notifyOnSuccess?: boolean;
  onFail?: (data: F) => Promise<void>;
  notifyOnFail?: boolean;
  onError?: (error: E) => void;
  notifyOnError?: boolean;
}

export function useJsendHandler<T, F = unknown, E = unknown>({
  jsendFn,
  onSuccess = () => Promise.resolve(),
  successMessage,
  notifyOnSuccess = true,
  onFail = () => Promise.resolve(),
  notifyOnFail = true,
  onError = () => {},
  notifyOnError = true,
}: JsendHandlerOptions<T, F, E> = {}): {
  fn: () => Promise<void>;
  errorFn: (e: E) => void;
  responseFn: (response: Promise<JsendResponse<T, F>>) => Promise<void>;
} {
  const { enqueueSnackbar } = useSnackbar();

  const handleJsendResponse = async (status: string, data: T | F) => {
    if (status === "fail") {
      await onFail(data as F);
      if (notifyOnFail) {
        if (typeof data === "string") enqueueSnackbar(data, { variant: "error" });
        else enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
      }
      return;
    }

    await onSuccess(data as T);
    if (notifyOnSuccess) {
      if (successMessage) enqueueSnackbar(successMessage, { variant: "success" });
      else if (typeof data === "string") enqueueSnackbar(data, { variant: "success" });
      else enqueueSnackbar("Success", { variant: "success" });
    }
  };

  const fn = async () => {
    if (!jsendFn) return Promise.resolve();
    const { status, data } = await jsendFn();
    await handleJsendResponse(status, data);
  };

  const responseFn = async (response: Promise<JsendResponse<T, F>>) => {
    const { status, data } = await response;
    await handleJsendResponse(status, data);
  };

  const errorFn = (e: E) => {
    console.error(e);
    if (notifyOnError) enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
    onError(e);
  };

  return {
    fn,
    errorFn,
    responseFn,
  };
}
