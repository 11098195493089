import { Box, BoxProps, Button, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

interface DomainSearchProps {
  placeholder?: string;
  formBoxSx?: BoxProps["sx"];
  textFieldProps?: TextFieldProps;
}
function DomainSearch({ formBoxSx = {}, textFieldProps = {}, placeholder }: DomainSearchProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();

        const domain = inputRef.current?.value;
        if (!domain) return;
        const query = new URLSearchParams({ domain }).toString();
        navigate(`/domains/?${query}`);
      }}
      sx={{ ...formBoxSx }}
    >
      <TextField
        id="domain"
        name="domain"
        sx={{
          backgroundColor: "common.white",
          borderRadius: "5px",
        }}
        placeholder={placeholder}
        inputRef={inputRef}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button variant="contained" color="primary" type="submit">
                Search
              </Button>
            </InputAdornment>
          ),
        }}
        {...textFieldProps}
      />
    </Box>
  );
}

export default DomainSearch;
