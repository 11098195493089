import { errorKeyResponseDecoder } from "Services/api/interfaces";
import { jsendResponseDecoder } from "Utils/jsend";
import { eitherDecoder } from "Utils/monads";
import { DecoderType, array, boolean, constant, dict, either, maybe, nullable, number, object, string } from "decoders";
import { Address } from "../addresses/interfaces";
import { CartPaymentMethod } from "../neosaccount/interfaces";

const cartProductsDecoder = either(constant("wordpress"), constant("ssl"));
export type CartProducts = DecoderType<typeof cartProductsDecoder>;
const sslValues = either(constant("1"), constant("2"), constant("3"), constant("4"), constant("5"));
export type SslValues = DecoderType<typeof sslValues>;
const wordpressValues = either(constant("monthly"), constant("yearly"));
export type WordpressValues = DecoderType<typeof wordpressValues>;

const defaultCartProductDecoder = object({
  enabled: boolean,
  autoRenew: boolean,
});
export type DefaultCartProduct = DecoderType<typeof defaultCartProductDecoder>;

const productsDecoder = object({
  domainPrivacy: defaultCartProductDecoder,
  premiumDNS: defaultCartProductDecoder,
  ssl: maybe(nullable(sslValues)),
  wordpress: maybe(nullable(wordpressValues)),
});

const item = {
  type: either(constant("registration"), constant("renewal")),
  iCannFee: number,
  autoRenew: boolean,
  years: number,
  products: productsDecoder,
};

const orderItemDecoder = object({ domain: string, ...item });
export type OrderItem = DecoderType<typeof orderItemDecoder>;

const cartItemDecoder = object({
  ...item,
  price: number,
});
export type CartItem = DecoderType<typeof cartItemDecoder>;

export const cartItemsDecoder = dict(cartItemDecoder);

const productsPricesDecoder = object({
  domainPrivacy: number,
  premiumDNS: number,
  ssl: object({
    "1": number,
    "2": number,
    "3": number,
    "4": number,
    "5": number,
  }),
  wordpress: object({
    monthly: number,
    yearly: number,
  }),
});
export type ProductsPrices = DecoderType<typeof productsPricesDecoder>;

export const cartDecoder = object({
  items: cartItemsDecoder,
  productsPrices: productsPricesDecoder,
});
export type Cart = DecoderType<typeof cartDecoder>;

export class CartDomainItem {
  name: string;
  data: CartItem;

  constructor(name: string, data: CartItem) {
    this.name = name;
    this.data = data;
  }

  get autoRenew(): boolean {
    return this.data.autoRenew;
  }

  get price(): number {
    return this.data.price;
  }

  get type(): CartItem["type"] {
    return this.data.type;
  }

  get years(): number {
    return this.data.years;
  }

  get iCannFee(): number {
    return this.data.iCannFee;
  }

  get products(): CartItem["products"] {
    return this.data.products;
  }

  get count(): number {
    let count = 1;
    if (this.data.products.premiumDNS.enabled) count++;
    if (this.data.products.ssl) count++;
    if (this.data.products.wordpress) count++;

    return count;
  }

  subtotal(prices: ProductsPrices): number {
    let price = this.data.price + this.data.iCannFee;

    if (this.data.products.premiumDNS.enabled) price += prices.premiumDNS;
    if (this.data.products.ssl) price += prices.ssl[this.data.products.ssl];
    if (this.data.products.wordpress) price += prices.wordpress[this.data.products.wordpress];

    return price;
  }

  toJson(updates: Partial<OrderItem> = {}): OrderItem {
    return {
      domain: this.name,
      type: this.type,
      iCannFee: this.iCannFee,
      autoRenew: this.autoRenew,
      years: this.years,
      products: this.products,
      ...updates,
    };
  }
}

export const cartAddressInitialValues: Address = {
  id: 0,
  firstName: "",
  lastName: "",
  companyName: "",
  jobTitle: "",
  isCompanyDomain: false,
  address1: "",
  address2: "",
  city: "",
  stateProvince: "",
  zipPostalCode: "",
  country: "US",
  areaCode: "+1",
  phoneNumber: "",
  phoneExt: "",
  hasPhoneExt: false,
  email: "",
};

export class ShoppingCart {
  private _data: Cart;
  private _domains: string[];
  private _items: CartDomainItem[];
  private _mapping: Record<string, number> = {};
  public paymentMethod: CartPaymentMethod = null;
  public billingAddress: Address = cartAddressInitialValues;

  constructor(data: Cart) {
    this._data = data;
    this._domains = [];
    this._items = [];
    for (const domain in data.items) {
      this._domains.push(domain);
      this.items.push(new CartDomainItem(domain, data.items[domain]));
      this._mapping[domain] = this.items.length - 1;
    }
  }

  get data(): Cart {
    return this._data;
  }

  get domains(): string[] {
    return this._domains;
  }

  get items(): CartDomainItem[] {
    return this._items;
  }

  get productsPrices(): ProductsPrices {
    return this._data.productsPrices;
  }

  get subtotal(): number {
    return Object.values(this.items).reduce((acc, item) => acc + item.subtotal(this._data.productsPrices), 0);
  }

  get count(): number {
    return Object.values(this.items).reduce((acc, item) => acc + item.count, 0);
  }

  /* istanbul ignore next */
  findDomainItem(domain: string): CartDomainItem | undefined {
    const index = this._mapping[domain];
    if (index === undefined) return undefined;
    return this.items[index];
  }

  getDomainItem(domain: string): CartDomainItem {
    const index = this._mapping[domain];
    /* istanbul ignore next */
    if (index === undefined) throw new Error("Domain not found");
    return this.items[index];
  }

  toJson(): OrderItem[] {
    return this.items.map((item) => item.toJson());
  }

  resetAddress(): void {
    this.billingAddress = cartAddressInitialValues;
  }

  resetPaymentMethod(): void {
    this.paymentMethod = null;
  }
}

export const addItemToCartResponseDecoder = jsendResponseDecoder(cartItemDecoder, string);
export type AddItemToCartResponse = DecoderType<typeof addItemToCartResponseDecoder>;

const addProductResponseDecoder = array(
  either(
    object({
      domain: string,
      product: constant("wordpress"),
      value: either(wordpressValues),
    }),
    object({
      domain: string,
      product: constant("ssl"),
      value: either(sslValues),
    }),
  ),
);
export type AddProductResponse = DecoderType<typeof addProductResponseDecoder>;
export const addProductToCartResponseDecoder = jsendResponseDecoder(addProductResponseDecoder, string);
export type AddProductToCartResponse = DecoderType<typeof addProductToCartResponseDecoder>;

export const updateWordpressResponseDecoder = jsendResponseDecoder(wordpressValues, string);
export type UpdateWordpressResponse = DecoderType<typeof updateWordpressResponseDecoder>;

export const updateSslResponseDecoder = jsendResponseDecoder(sslValues, string);
export type UpdateSslResponse = DecoderType<typeof updateSslResponseDecoder>;

export const FailedPayCartResponseDecoder = eitherDecoder(errorKeyResponseDecoder, string);
export const payCartResponseDecoder = jsendResponseDecoder(string, FailedPayCartResponseDecoder);
export type PayCartResponse = DecoderType<typeof payCartResponseDecoder>;
