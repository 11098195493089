import { AddShoppingCart, ShoppingCartCheckout } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Container,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { MoneyFormat } from "@nc/neoscloud-common-react";
import { useQuery } from "@tanstack/react-query";
import { useCartContext, useMainDataContext } from "Containers/MainContainer/MainProvider";
import { addItemToCart } from "Services/api/cart/cart";
import { searchDomain } from "Services/api/domains/domains";
import { Query } from "Shared/Query/Query";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { useJsendMutation } from "../../../hooks/useJsendMutation";

function Domains() {
  const [searchParams] = useSearchParams({});
  const [search, setSearch] = useState(searchParams.get("domain") || "");
  const [{ state }] = useMainDataContext();
  const [cart] = useCartContext();
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [searchDomain.name],
    queryFn: async () => {
      if (!search) return [];
      const { status, data } = await searchDomain(search);
      if (status === "fail") {
        enqueueSnackbar(data, { variant: "error" });
        return [];
      }
      return data;
    },
    cacheTime: 0,
  });

  return state === "init" ? (
    <></>
  ) : (
    <Fragment>
      <Stack component={Container} sx={{ mb: 10 }} spacing={3}>
        <Box
          component="form"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            void result.refetch();
          }}
        >
          <TextField
            id="domain"
            name="domain"
            sx={{
              backgroundColor: "common.white",
              borderRadius: "5px",
              width: "100%",
              mt: 3,
            }}
            placeholder="Search for domains..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" color="primary" type="submit">
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Box>
        <Typography color="inherit" variant="h6">
          Domains
        </Typography>
        <TableContainer component={Paper} elevation={2}>
          <Table aria-label="results">
            <TableHead>
              <TableRow>
                <TableCell scope="row" colSpan={3} sx={{ fontWeight: 500 }}>
                  Results
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Query
                result={result}
                OnLoading={() => (
                  <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row" sx={{ width: "100%", fontSize: "18px", fontWeight: 600 }}>
                      Searching domains...
                    </TableCell>
                  </TableRow>
                )}
                onSuccess={(domains) => {
                  return (
                    <Fragment>
                      {domains.length > 0 ? (
                        domains.map((item) => (
                          <TableRow hover key={item.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell
                              scope="row"
                              colSpan={3}
                              sx={(theme) => ({
                                [theme.breakpoints.up("sm")]: {
                                  display: "none",
                                },
                              })}
                            >
                              <Stack spacing={2} data-testid="domain-xs">
                                <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>{item.name}</Typography>
                                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                                  <MoneyFormat amount={item.price} />
                                  /yr
                                </Typography>
                                <AddToCart domain={item.name} />
                              </Stack>
                            </TableCell>

                            <Fragment>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={(theme) => ({
                                  width: "100%",
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  display: "none",
                                  [theme.breakpoints.up("sm")]: {
                                    display: "table-cell",
                                  },
                                })}
                              >
                                {item.name}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={(theme) => ({
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  display: "none",
                                  [theme.breakpoints.up("sm")]: {
                                    display: "table-cell",
                                  },
                                })}
                              >
                                <MoneyFormat amount={item.price} />
                                /yr
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={(theme) => ({
                                  display: "none",
                                  [theme.breakpoints.up("sm")]: {
                                    minWidth: "170px",
                                    display: "table-cell",
                                  },
                                })}
                              >
                                <AddToCart domain={item.name} />
                              </TableCell>
                            </Fragment>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ width: "100%", fontSize: "18px", fontWeight: 600 }}
                          >
                            No results found
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  );
                }}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {cart && cart.count > 0 && (
        <AppBar component={"div"} elevation={0} position="sticky" sx={{ top: "auto", bottom: 0 }} color="secondary">
          <Container
            component={Toolbar}
            maxWidth="xl"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              direction="row"
              spacing={2}
            >
              <Stack alignItems="flex-end">
                <Typography sx={{ fontSize: ".75rem", fontWeight: 500 }}>
                  {cart.count} {cart.count > 1 ? "items" : "item"}
                </Typography>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: 700 }}>
                  <MoneyFormat amount={cart.subtotal} />
                </Typography>
              </Stack>
              <Button component={RouterLink} to="/cart" variant="contained" startIcon={<ShoppingCartCheckout />}>
                Checkout
              </Button>
            </Stack>
          </Container>
        </AppBar>
      )}
    </Fragment>
  );
}

interface AddToCartProps {
  domain: string;
}

function AddToCart({ domain }: AddToCartProps) {
  const [cart, setCart] = useCartContext();

  const { mutateAsync: onAdd, isLoading } = useJsendMutation({
    jsendFn: () => addItemToCart(domain, "registration"),
    onSuccess: async (data) => {
      cart.data.items[domain] = data;
      await setCart({ ...cart.data.items });
    },
    successMessage: `Added ${domain} to cart`,
  });

  return domain in cart.data.items ? (
    <Button fullWidth disabled>
      In cart
    </Button>
  ) : (
    <Button
      variant="contained"
      startIcon={<AddShoppingCart />}
      fullWidth
      sx={{ textTransform: "capitalize" }}
      onClick={() => void onAdd()}
      disabled={isLoading}
    >
      Add to cart
    </Button>
  );
}

export default Domains;
