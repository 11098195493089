import { Close } from "@mui/icons-material";
import { Button, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { FormikCheckbox, FormikForm, FormikSubmitButton } from "@nc/neoscloud-common-react";
import { cartAddressInitialValues } from "Services/api/cart/interfaces";
import { ContactFormFields } from "Shared/ContactFormFields/ContactFormFields";
import { Formik } from "formik";
import { useParams } from "react-router-dom";

export type ContactType = "registrant" | "billing" | "administrator" | "technical";

export default function Contact() {
  const { domain, type } = useParams<{ domain: string; type: ContactType }>();

  if (!domain) return <>Domain not found for this user</>;
  if (!type || !["registrant", "billing", "administrator", "technical"].includes(type))
    return <>Invalid contact type</>;

  return (
    <Stack>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Edit {type} contact</Typography>
        <IconButton color="primary" href={`/account/domains/${domain}/`} aria-label="Back to domain">
          <Close />
        </IconButton>
      </Grid>

      <Divider sx={{ my: "25px" }} />
      <Formik
        initialValues={{
          address: cartAddressInitialValues,
          forRegistrant: type === "registrant",
          forBilling: type === "billing",
          forAdministrator: type === "administrator",
          forTechnical: type === "technical",
        }}
        onSubmit={(_, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        <FormikForm>
          <ContactFormFields />
          {type !== "registrant" && (
            <FormikCheckbox id="forRegistrant" name="forRegistrant" label="Use for registrant contact" />
          )}
          {type !== "billing" && <FormikCheckbox id="forBilling" name="forBilling" label="Use for billing contact" />}
          {type !== "administrator" && (
            <FormikCheckbox id="forAdministrator" name="forAdministrator" label="Use for administrator contact" />
          )}
          {type !== "technical" && (
            <FormikCheckbox id="forTechnical" name="forTechnical" label="Use for technical contact" />
          )}
          <FormikSubmitButton variant="contained" aria-label="Save contact changes">
            Save
          </FormikSubmitButton>
          <Button color="error" href={`/account/domains/${domain}/`} aria-label="Cancel contact changes">
            Cancel
          </Button>
        </FormikForm>
      </Formik>
    </Stack>
  );
}
