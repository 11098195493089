import { Cancel, Delete, Done, Edit, List } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useCartContext } from "Containers/MainContainer/MainProvider";
import { ContactType } from "Pages/Contact/Contact";
import { addItemToCart } from "Services/api/cart/cart";
import { ContactAddress, ContactAddressProps } from "Shared/ContactAddress/ContactAddress";
import { Dispatch, Fragment, MouseEvent, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useJsendMutation } from "../../../hooks/useJsendMutation";

function Domain() {
  const { domain } = useParams();
  const [nameserversType, setNameserversType] = useState<"basic" | "custom">("basic");

  if (!domain) return <>Domain not found for this user</>;

  return (
    <Stack spacing={3}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{domain}</Typography>
        <IconButton color="primary" href={`/account/domains/`} aria-label="Go to domains list">
          <List />
        </IconButton>
      </Grid>
      <StatusValiditySection domain={domain} />
      <Divider />
      <Grid container alignItems="center">
        <Grid item xs={12} lg={3}>
          <Typography>
            <strong>Domain Privacy</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <DomainProtection name={domain} protection />
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={5}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <Typography>Oct 11, 2021 - Oct 11, 2022</Typography>
            </Grid>
            <Grid item xs={4}>
              <DomainAutoRenew name={domain} autoRenew />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <Button variant="contained" fullWidth>
            Add Years
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <Grid container alignItems="center">
        <Grid item xs={12} lg={3}>
          <Typography>
            <strong>Premium DNS</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={7}>
          <Typography variant="body2">
            Enable PremiumDNS protection in order to switch your domain to our PremiumDNS platform. With our PremiumDNS
            platform, you get 100% DNS uptime and DDoS protection at the DNS level.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <Button variant="contained" fullWidth>
            Buy now
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <NameServersSection domain={domain} nameserversType={nameserversType} setNameserversType={setNameserversType} />
      <Divider />
      <HostRecordsSection nameserversType={nameserversType} />
      <Divider />
      <DomainRedirectSection />
      <Divider />
      <EmailRedirectSection />
      <Divider />
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={3}>
            <Typography>
              <strong>Whois Contacts</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Typography variant="body2">
              Each domain registered must include contact details. These are stored in a public WHOIS database so that
              the domain owner can be contacted, as needed.
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} lg={3}>
            <DomainAddress
              type="billing"
              contact={{
                id: 0,
                firstName: "John",
                lastName: "Doe",
                address1: "123 Fake Street",
                address2: "Apt 1",
                city: "New York",
                stateProvince: "NY",
                zipPostalCode: "10001",
                country: "US",
                phoneNumber: "555-555-5555",
                email: "johndoe@email.com",
                companyName: "",
                jobTitle: "",
                isCompanyDomain: false,
                areaCode: "+1",
                phoneExt: "",
                hasPhoneExt: false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DomainAddress
              type="registrant"
              contact={{
                id: 0,
                firstName: "John",
                lastName: "Doe",
                address1: "123 Fake Street",
                address2: "Apt 1",
                city: "New York",
                stateProvince: "NY",
                zipPostalCode: "10001",
                country: "US",
                phoneNumber: "555-555-5555",
                email: "johndoe@email.com",
                companyName: "",
                jobTitle: "",
                isCompanyDomain: false,
                areaCode: "+1",
                phoneExt: "",
                hasPhoneExt: false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DomainAddress
              type="administrator"
              contact={{
                id: 0,
                firstName: "John",
                lastName: "Doe",
                address1: "123 Fake Street",
                address2: "Apt 1",
                city: "New York",
                stateProvince: "NY",
                zipPostalCode: "10001",
                country: "US",
                phoneNumber: "555-555-5555",
                email: "johndoe@email.com",
                companyName: "",
                jobTitle: "",
                isCompanyDomain: false,
                areaCode: "+1",
                phoneExt: "",
                hasPhoneExt: false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DomainAddress
              type="technical"
              contact={{
                id: 0,
                firstName: "John",
                lastName: "Doe",
                address1: "123 Fake Street",
                address2: "Apt 1",
                city: "New York",
                stateProvince: "NY",
                zipPostalCode: "10001",
                country: "US",
                phoneNumber: "555-555-5555",
                email: "johndoe@email.com",
                companyName: "",
                jobTitle: "",
                isCompanyDomain: false,
                areaCode: "+1",
                phoneExt: "",
                hasPhoneExt: false,
              }}
            />
          </Grid>
        </Grid>
      </Stack>
      <Divider />
      <Grid container alignItems="center">
        <Grid item xs={12} lg={3}>
          <Typography>
            <strong>Other Domain Settings</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          Parking Page
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          On
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Button variant="contained" fullWidth>
            Turn Off
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} lg={3}></Grid>
        <Grid item xs={12} md={3} lg={3}>
          Sell Domain
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Typography variant="body2">List a domain on the Marketplace</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Button variant="contained" fullWidth>
            Sell Domain
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}

interface StatusValiditySectionProps {
  domain: string;
}

function StatusValiditySection({ domain }: StatusValiditySectionProps) {
  const [cart, setCart] = useCartContext();
  const navigate = useNavigate();

  const { mutateAsync: onAdd, isLoading } = useJsendMutation({
    jsendFn: () => addItemToCart(domain, "renewal"),
    onSuccess: async (data) => {
      cart.data.items[domain] = data;
      await setCart({ ...cart.data.items });
      navigate("/cart");
    },
    successMessage: `Added ${domain} renewal to cart`,
  });

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} lg={3}>
        <Typography>
          <strong>Status & Validity</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <Typography>Active</Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={6} lg={5}>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <Typography>Oct 11, 2021 - Oct 11, 2022</Typography>
          </Grid>
          <Grid item xs={4}>
            <DomainAutoRenew name={domain} autoRenew />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => void onAdd()}
          disabled={isLoading}
          aria-label="Add years to the domain ownership"
        >
          Add Years
        </Button>
      </Grid>
    </Grid>
  );
}

interface DomainAutoRenewProps {
  name: string;
  autoRenew: boolean;
}

function DomainAutoRenew({ autoRenew }: DomainAutoRenewProps) {
  return (
    <FormControlLabel
      value="bottom"
      data-testid="auto-renew-domain"
      control={<Switch color="primary" checked={autoRenew} />}
      sx={{ width: "100%", m: 0 }}
      label="AUTO-RENEW"
      labelPlacement="bottom"
      componentsProps={{
        typography: {
          variant: "caption",
        },
      }}
    />
  );
}

interface DomainProtectionProps {
  name: string;
  protection: boolean;
}

function DomainProtection({ protection }: DomainProtectionProps) {
  return (
    <FormControlLabel
      value="bottom"
      data-testid="domain-privacy"
      control={<Switch color="primary" checked={protection} />}
      sx={{ m: 0 }}
      label="PROTECTION"
      labelPlacement="bottom"
      componentsProps={{
        typography: {
          variant: "caption",
        },
      }}
    />
  );
}

interface DomainAddressProps {
  type: ContactType;
  contact: ContactAddressProps["contact"];
}

function DomainAddress(props: DomainAddressProps) {
  const { type, contact } = props;
  const title = type.charAt(0).toUpperCase() + type.slice(1) + " Contact";

  return (
    <Stack spacing={1} marginBottom="15px">
      <Typography>
        <strong>{title}</strong>
      </Typography>
      <ContactAddress contact={contact} variant="body2" />
      <Button variant="contained" sx={{ width: "fit-content" }} href={`contact/${type}/`}>
        Edit
      </Button>
    </Stack>
  );
}

interface NameServersSectionProps {
  domain: string;
  nameserversType: "basic" | "custom";
  setNameserversType: Dispatch<SetStateAction<"basic" | "custom">>;
}

function NameServersSection({ domain, nameserversType, setNameserversType }: NameServersSectionProps) {
  const [nameservers, setNameservers] = useState<string[]>(["", ""]);

  return (
    <Fragment>
      <Grid container alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography>
            <strong>Nameservers</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Select
            value={nameserversType}
            SelectDisplayProps={{
              "aria-label": `Select name servers for ${domain}`,
            }}
            onChange={(event) => setNameserversType(event.target.value as "basic" | "custom")}
            fullWidth
          >
            <MenuItem value={"basic"}>Basic DNS</MenuItem>
            <MenuItem value={"custom"}>Custom DNS</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {nameserversType === "custom" && (
        <Fragment>
          {nameservers.map((nameserver, index) => (
            <NameServerInput
              key={index}
              nameservers={nameservers}
              nameserver={nameserver}
              index={index}
              setNameservers={setNameservers}
            />
          ))}
          <Grid container alignItems="center">
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={9}>
              <Stack direction="row" columnGap={2}>
                <Button
                  aria-label="Add nameserver"
                  variant="contained"
                  onClick={() => setNameservers([...nameservers, ""])}
                >
                  Add
                </Button>
                <Button variant="contained" aria-label="Save custom nameservers">
                  Save
                </Button>
                <Button
                  variant="contained"
                  aria-label="Clear custom nameservers"
                  onClick={() => {
                    setNameservers(["", ""]);
                    setNameserversType("basic");
                  }}
                >
                  Clear
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}

type HostRecordType = "CNAME" | "A" | "AAAA" | "URL Redirect";
type TTL = 1 | 5 | 20 | 30 | 60;
type UrlRedirectType = "Permanent (301)" | "Masked" | "Unmasked";

type HostRecord =
  | { id: number | string; type: "CNAME" | "A" | "AAAA"; host: string; value: string; ttl: TTL; saved: boolean }
  | {
      id: number | string;
      type: "URL Redirect";
      host: string;
      value: {
        url: string;
        type: UrlRedirectType;
      };
      ttl: null;
      saved: boolean;
    };
interface HostRecordsSectionProps {
  nameserversType: "basic" | "custom";
}

function HostRecordsSection({ nameserversType }: HostRecordsSectionProps) {
  const [hostRecords, setHostRecords] = useState<HostRecord[]>([
    {
      id: 1,
      type: "CNAME",
      host: "www",
      value: "example.com",
      ttl: 60,
      saved: true,
    },
    {
      id: 2,
      type: "A",
      host: "example.com",
      value: "0.0.0.0",
      ttl: 60,
      saved: true,
    },
    {
      id: 3,
      type: "URL Redirect",
      host: "example.com",
      value: {
        url: "https://example.com",
        type: "Permanent (301)",
      },
      ttl: null,
      saved: true,
    },
  ]);
  const [lastNewId, setLastNewId] = useState(hostRecords.length);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAdd = (type: HostRecordType) => {
    const newHostRecords = [...hostRecords];
    const newID = lastNewId + 1;
    if (type === "URL Redirect")
      newHostRecords.push({
        id: `new-${newID}`,
        type,
        host: "",
        value: {
          url: "",
          type: "Unmasked",
        },
        ttl: null,
        saved: false,
      });
    else
      newHostRecords.push({
        id: `new-${newID}`,
        type,
        host: "",
        value: "",
        ttl: 60,
        saved: false,
      });

    setHostRecords(newHostRecords);
    setLastNewId(newID);
    handleClose();
  };

  return (
    <Fragment>
      <Grid container alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography>
            <strong>Host Records</strong>
          </Typography>
        </Grid>
        {nameserversType === "custom" && (
          <Grid item xs={12} md={9}>
            <Typography variant="body2">
              You can manage host records in your cPanel account, or transfer DNS back to NeosCloud Basic DNS to manage
              the records here.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} marginTop={"10px"}>
          <Divider light />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Host</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>TTL</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hostRecords.map((record, index) => (
                <HostRecordOption
                  key={record.id}
                  record={record}
                  index={index}
                  hostRecords={hostRecords}
                  setHostRecords={setHostRecords}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5}>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    aria-controls="host-record-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    Add New Record
                  </Button>
                  <Menu
                    id="host-record-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "host-record-menu",
                    }}
                  >
                    <MenuItem onClick={() => onAdd("CNAME")}>CNAME Record</MenuItem>
                    <MenuItem onClick={() => onAdd("A")}>A Record</MenuItem>
                    <MenuItem onClick={() => onAdd("AAAA")}>AAAA Record</MenuItem>
                    <MenuItem onClick={() => onAdd("URL Redirect")}>URL Redirect</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </Grid>
    </Fragment>
  );
}

interface HostRecordOptionProps {
  record: HostRecord;
  index: number;
  hostRecords: HostRecord[];
  setHostRecords: Dispatch<SetStateAction<HostRecord[]>>;
}

function HostRecordOption({ record, index, hostRecords, setHostRecords }: HostRecordOptionProps) {
  const [localRecord, setRecord] = useState<HostRecord>(record);
  const { type, host, value, ttl, saved } = localRecord;

  const onRemove = () => {
    const newHostRecords = [...hostRecords];
    newHostRecords.splice(index, 1);
    setHostRecords(newHostRecords);
  };

  const onSave = () => {
    const newHostRecords = [...hostRecords];
    newHostRecords[index] = { ...localRecord, saved: true };
    setHostRecords(newHostRecords);
  };

  const onEdit = () => {
    const newHostRecords = [...hostRecords];
    newHostRecords[index] = { ...localRecord, saved: false };
    setHostRecords(newHostRecords);
  };

  const onCancel = () => {
    if (record.id.toString().includes("new")) {
      onRemove();
      return;
    }
    const newHostRecords = [...hostRecords];
    newHostRecords[index] = { ...record, saved: true };
    setHostRecords(newHostRecords);
  };

  useEffect(() => {
    setRecord(record);
  }, [record]);

  if (saved)
    return (
      <TableRow>
        <TableCell>{type} Record</TableCell>
        <TableCell>{host}</TableCell>
        <TableCell>
          {type === "URL Redirect" ? (
            <Stack direction="row" spacing={3} flexWrap="wrap" alignItems="center">
              <Box>{value.url}</Box>
              <Box>{value.type}</Box>
            </Stack>
          ) : (
            value
          )}
        </TableCell>
        <TableCell>
          {ttl} {ttl && "min"}
        </TableCell>
        <TableCell align="right">
          <IconButton color="primary" aria-label="Edit host record" onClick={onEdit}>
            <Edit />
          </IconButton>
          <IconButton color="primary" aria-label="Remove host record" onClick={onRemove}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    );

  let valuePlaceholder = "";

  switch (type) {
    case "CNAME":
      valuePlaceholder = "Target";
      break;
    case "A":
      valuePlaceholder = "IP Address";
      break;
    case "AAAA":
      valuePlaceholder = "IPv6 Address";
      break;
    case "URL Redirect":
      valuePlaceholder = "Destination URL";
      break;
  }

  return (
    <TableRow>
      <TableCell>{type} Record</TableCell>
      <TableCell>
        <TextField
          value={host}
          onChange={(event) => {
            setRecord({ ...localRecord, host: event.target.value });
          }}
          onBlur={(event) => {
            setRecord({ ...localRecord, host: event.target.value });
          }}
          size="small"
          required
          fullWidth
          placeholder="Host"
          inputProps={{ "aria-label": `Host for ${type} record` }}
        />
      </TableCell>
      <TableCell>
        {type === "URL Redirect" ? (
          <Stack direction="row" spacing={3} flexWrap="wrap" alignItems="center">
            <Box>
              <TextField
                value={value.url}
                onChange={(event) => {
                  setRecord({ ...localRecord, value: { ...value, url: event.target.value } });
                }}
                onBlur={(event) => {
                  setRecord({ ...localRecord, value: { ...value, url: event.target.value } });
                }}
                size="small"
                required
                fullWidth
                placeholder={valuePlaceholder}
                inputProps={{ "aria-label": `Value for ${type} record` }}
              />
            </Box>
            <Box>
              <Select
                value={value.type}
                onChange={(event) =>
                  setRecord({ ...localRecord, value: { ...value, type: event.target.value as UrlRedirectType } })
                }
              >
                <MenuItem value="Permanent (301)">Permanent (301)</MenuItem>
                <MenuItem value="Masked">Masked</MenuItem>
                <MenuItem value="Unmasked">Unmasked</MenuItem>
              </Select>
            </Box>
          </Stack>
        ) : (
          <TextField
            value={value}
            onChange={(event) => {
              setRecord({ ...localRecord, value: event.target.value });
            }}
            onBlur={(event) => {
              setRecord({ ...localRecord, value: event.target.value });
            }}
            size="small"
            required
            fullWidth
            placeholder={valuePlaceholder}
            inputProps={{ "aria-label": `Value for ${type} record` }}
          />
        )}
      </TableCell>
      <TableCell>
        {type !== "URL Redirect" && (
          <Select value={ttl} onChange={(event) => setRecord({ ...localRecord, ttl: event.target.value as TTL })}>
            <MenuItem value={1}>1 min</MenuItem>
            <MenuItem value={5}>5 min</MenuItem>
            <MenuItem value={20}>20 min</MenuItem>
            <MenuItem value={30}>30 min</MenuItem>
            <MenuItem value={60}>60 min</MenuItem>
          </Select>
        )}
      </TableCell>
      <TableCell align="right">
        <IconButton color="success" aria-label={`Save ${type} record`} onClick={onSave}>
          <Done />
        </IconButton>
        <IconButton color="error" aria-label={`Cancel ${type} record`} onClick={onCancel}>
          <Cancel />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

interface NameServerInputProps {
  nameservers: string[];
  nameserver: string;
  index: number;
  setNameservers: Dispatch<SetStateAction<string[]>>;
}

function NameServerInput({ nameservers, nameserver, index, setNameservers }: NameServerInputProps) {
  const [ns, setNs] = useState(nameserver);

  return (
    <Grid container alignItems="center" style={{ marginTop: "12px" }}>
      <Grid item xs={12} md={3}></Grid>
      <Grid item xs={12} md={9}>
        <TextField
          inputProps={{ "aria-label": `Nameserver ${index + 1}` }}
          placeholder={`Nameserver ${index + 1}`}
          value={ns}
          onChange={(event) => {
            setNs(event.target.value);
          }}
          onBlur={(event) => {
            const newNameservers = [...nameservers];
            newNameservers[index] = event.target.value;
            setNameservers(newNameservers);
          }}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

interface Redirect {
  from: string;
  to: string;
  saved: boolean;
  isGeneralRedirect: boolean;
}

function DomainRedirectSection() {
  const [redirects, setRedirects] = useState<Redirect[]>([]);

  return (
    <RedirectSection
      type="domain"
      title="Redirect Domain"
      fromHeader="Source URL"
      toHeader="Destination URL"
      addLabel="Add Redirect"
      generalLabel="Wildcard Redirect"
      addGeneralLabel="Add Wildcard Redirect"
      fromInputAriaLabel="Domain redirect source URL"
      toInputAriaLabel="Domain redirect destination URL"
      redirects={redirects}
      setRedirects={setRedirects}
    />
  );
}

function EmailRedirectSection() {
  const [redirects, setRedirects] = useState<Redirect[]>([]);

  return (
    <RedirectSection
      type="email"
      title="Redirect Email"
      fromHeader="Alias"
      toHeader="Forward to"
      addLabel="Add Forwarder"
      generalLabel="Catch-All"
      addGeneralLabel="Add Catch-All"
      fromInputAriaLabel="Email redirect alias"
      toInputAriaLabel="Email redirect forward to"
      redirects={redirects}
      setRedirects={setRedirects}
    />
  );
}

interface RedirectSectionProps {
  type: "domain" | "email";
  title: string;
  fromHeader: string;
  toHeader: string;
  addLabel: string;
  generalLabel: string;
  addGeneralLabel: string;
  fromInputAriaLabel: string;
  toInputAriaLabel: string;
  redirects: Redirect[];
  setRedirects: Dispatch<SetStateAction<Redirect[]>>;
}

function RedirectSection({
  type,
  title,
  fromHeader,
  toHeader,
  addLabel,
  generalLabel,
  addGeneralLabel,
  fromInputAriaLabel,
  toInputAriaLabel,
  redirects,
  setRedirects,
}: RedirectSectionProps) {
  const hasGeneralRedirect = redirects.some((redirect) => redirect.isGeneralRedirect);
  const hasMultipleUnsaved = redirects.filter((redirect) => !redirect.saved).length > 1;

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} lg={3}>
        <Typography>
          <strong>{title}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Table>
          {redirects.length ? (
            <Fragment>
              <TableHead>
                <TableRow>
                  <TableCell>{fromHeader}</TableCell>
                  <TableCell>{toHeader}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {redirects.map((redirect, index) => (
                  <RedirectOption
                    key={index}
                    type={type}
                    redirect={redirect}
                    index={index}
                    fromInputAriaLabel={fromInputAriaLabel}
                    toInputAriaLabel={toInputAriaLabel}
                    redirects={redirects}
                    setRedirects={setRedirects}
                    saveAriaLabel={
                      redirect.isGeneralRedirect ? `Save ${generalLabel.toLowerCase()}` : `Save ${type} redirect`
                    }
                  />
                ))}
              </TableBody>
            </Fragment>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>You haven&apos;t created any {type} redirect.</TableCell>
              </TableRow>
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3}>
                <Stack direction="row" columnGap={1}>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      const newRedirects = [...redirects];
                      newRedirects.push({
                        from: "",
                        to: "",
                        saved: false,
                        isGeneralRedirect: false,
                      });
                      setRedirects(newRedirects);
                    }}
                  >
                    {addLabel}
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      if (hasGeneralRedirect) return;

                      const newRedirects = [...redirects];
                      newRedirects.push({
                        from: generalLabel,
                        to: "",
                        saved: false,
                        isGeneralRedirect: true,
                      });
                      setRedirects(newRedirects);
                    }}
                    disabled={!!hasGeneralRedirect}
                  >
                    {addGeneralLabel}
                  </Button>
                  {hasMultipleUnsaved && (
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      aria-label={`Save all ${type} redirects`}
                      onClick={() => {
                        if (!hasMultipleUnsaved) return;

                        const newRedirects = [...redirects];
                        newRedirects.forEach((redirect) => {
                          redirect.saved = true;
                        });
                        setRedirects(newRedirects);
                      }}
                    >
                      Save All
                    </Button>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
    </Grid>
  );
}

interface RedirectOptionProps {
  type: "domain" | "email";
  redirect: Redirect;
  index: number;
  fromInputAriaLabel: string;
  toInputAriaLabel: string;
  saveAriaLabel: string;
  redirects: Redirect[];
  setRedirects: Dispatch<SetStateAction<Redirect[]>>;
}

function RedirectOption({
  type,
  redirect,
  index,
  fromInputAriaLabel,
  toInputAriaLabel,
  saveAriaLabel,
  redirects,
  setRedirects,
}: RedirectOptionProps) {
  const [{ from, to, saved, isGeneralRedirect }, setRedirect] = useState<Redirect>(redirect);

  const onRemove = () => {
    const newRedirects = [...redirects];
    newRedirects.splice(index, 1);
    setRedirects(newRedirects);
  };

  const onSave = () => {
    const newRedirects = [...redirects];
    newRedirects[index] = { ...redirect, saved: true };
    setRedirects(newRedirects);
  };

  useEffect(() => {
    setRedirect(redirect);
  }, [redirect]);

  if (saved)
    return (
      <TableRow>
        <TableCell>
          <Typography variant="body2">{from}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{to}</Typography>
        </TableCell>
        <TableCell align="right">
          <IconButton color="primary" aria-label={`Remove ${type} redirect`} onClick={onRemove}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    );

  return (
    <TableRow>
      <TableCell>
        {isGeneralRedirect ? (
          <Typography variant="body2">{from}</Typography>
        ) : (
          <TextField
            value={from}
            onChange={(event) => {
              setRedirect({ ...redirect, from: event.target.value });
            }}
            onBlur={(event) => {
              const newRedirects = [...redirects];
              newRedirects[index] = { ...redirect, from: event.target.value };
              setRedirects(newRedirects);
            }}
            size="small"
            fullWidth
            inputProps={{ "aria-label": fromInputAriaLabel }}
          />
        )}
      </TableCell>
      <TableCell>
        <TextField
          value={to}
          onChange={(event) => {
            setRedirect({ ...redirect, to: event.target.value });
          }}
          onBlur={(event) => {
            const newRedirects = [...redirects];
            newRedirects[index] = { ...redirect, to: event.target.value };
            setRedirects(newRedirects);
          }}
          size="small"
          fullWidth
          inputProps={{ "aria-label": toInputAriaLabel }}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton color="success" aria-label={saveAriaLabel} onClick={onSave}>
          <Done />
        </IconButton>
        <IconButton color="error" aria-label={`Cancel ${type} redirect`} onClick={onRemove}>
          <Cancel />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default Domain;
