import { jsendResponseDecoder } from "Utils/jsend";
import { DecoderType, array, number, object, string } from "decoders";

const domainDecoder = object({
  name: string,
  price: number,
  icannFee: number,
});

export type Domain = DecoderType<typeof domainDecoder>;

const domainsDecoder = array(domainDecoder);

export type Domains = DecoderType<typeof domainsDecoder>;

export const domainSearchResponseDecoder = jsendResponseDecoder(domainsDecoder, string);

export type DomainSearchResponse = DecoderType<typeof domainSearchResponseDecoder>;
