import { ProviderContext } from "notistack";
import { curry } from "ramda";

export const showSnackbarErrorsObject = curry(function (
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  data: Record<string, string[]>,
) {
  Object.entries(data)
    .map(([key, value]) => value.map((error) => `${key}: ${error}`))
    .flat()
    .forEach((errorMessage) =>
      enqueueSnackbar(errorMessage, {
        variant: "error",
      }),
    );
});

export const showSnackbarErrorMessage = curry(function (
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  errorMessage: string,
) {
  enqueueSnackbar(errorMessage, {
    variant: "error",
  });
});
