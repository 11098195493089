import { Box, Container, Grid, Link, Stack, Typography } from "@mui/material";
import { LoginForm, LoginState } from "@nc/neoscloud-common-react";
import { useMutation } from "@tanstack/react-query";
import { useCartContext, useMainDataContext } from "Containers/MainContainer/MainProvider";
import logo from "Public/NeoscloudLogo2.png";
import { login } from "Services/api/auth/auth";
import { FailedLoginResponse } from "Services/api/auth/interfaces";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { NEOSACCOUNT_URL } from "Utils/envVariables";
import { JsendFail } from "Utils/jsend";
import { showSnackbarErrorMessage, showSnackbarErrorsObject } from "Utils/snackbar";
import { ProviderContext, useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const [{ state }] = useMainDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (state === "logged") navigate("/account/dashboard");
  }, [state, navigate]);

  return (
    <Container maxWidth="sm">
      <Stack
        sx={{
          border: "1px solid rgb(218, 220, 224)",
          borderRadius: "8px",
          padding: "40px",
        }}
        alignItems="center"
      >
        <Box
          src={logo}
          sx={{
            width: "60px",
            paddingBottom: "20px",
          }}
          component="img"
          alt="Neoscloud logo"
        />
        <Typography component="h1" variant="h5">
          {"Sign in"}
        </Typography>
        <LoginPageForm />
      </Stack>
    </Container>
  );
}

function LoginPageForm() {
  const [, setMain] = useMainDataContext();
  const [cart] = useCartContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { mutateAsync: onLogin } = useMutation({
    mutationFn: async ({ username, password, keepSignedIn }: LoginState) => {
      const result = await login(username, password, keepSignedIn, cart.toJson());

      if (result.status === "fail") {
        handleFailedLogin(result, enqueueSnackbar);
        return;
      }

      await setMain(result.data.data);

      enqueueSnackbar(result.data.message, {
        variant: "success",
      });

      navigate("/account/dashboard");
    },
    onError: (error) => {
      enqueueSnackbar(GENERIC_ERROR_MESSAGE, {
        variant: "error",
      });
      console.error(error);
    },
  });

  return (
    <LoginForm
      onLogin={async ({ username, password, keepSignedIn }: LoginState) => {
        await onLogin({ username, password, keepSignedIn });
      }}
    >
      <Grid item xs>
        <Link href={`${NEOSACCOUNT_URL}/restore/`} variant="body2">
          Forgot password?
        </Link>
      </Grid>
      <Grid item>
        <Link href={`${NEOSACCOUNT_URL}/signup/`} variant="body2">
          {"Don't have an account? Sign Up"}
        </Link>
      </Grid>
    </LoginForm>
  );
}

function handleFailedLogin(
  result: JsendFail<FailedLoginResponse>,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
) {
  result.data.match({
    left: showSnackbarErrorsObject(enqueueSnackbar),
    right: showSnackbarErrorMessage(enqueueSnackbar),
  });
}

export default Login;
