import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { JsendHandlerOptions, useJsendHandler } from "./useJsendHandler";

export function useJsendMutation<T, F, E>({
  jsendFn,
  onSuccess = () => Promise.resolve(),
  successMessage,
  notifyOnSuccess = true,
  onFail = () => Promise.resolve(),
  notifyOnFail = true,
  onError = () => {},
  notifyOnError = true,
}: JsendHandlerOptions<T, F, E>): UseMutationResult<void, unknown, void, unknown> {
  const { fn, errorFn } = useJsendHandler({
    jsendFn,
    onSuccess,
    successMessage,
    notifyOnSuccess,
    onFail,
    notifyOnFail,
    onError,
    notifyOnError,
  });

  return useMutation({
    mutationFn: fn,
    onError: errorFn,
  });
}
