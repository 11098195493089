import { BrowserRouter as Router } from "react-router-dom";
import { App, AppRoutes } from "./App";
import { createEmotionCache } from "./createEmotionCache";

const cache = createEmotionCache();

function BrowserApp() {
  return (
    <App cache={cache}>
      <Router>
        <AppRoutes />
      </Router>
    </App>
  );
}

export default BrowserApp;
