import { Fragment } from "react";
import Hero from "./Hero";
import Values from "./Values";

function Home() {
  return (
    <Fragment>
      <Hero />
      <Values />
    </Fragment>
  );
}

export default Home;
