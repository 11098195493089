import { Search } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { MoneyFormat } from "@nc/neoscloud-common-react";
import { useCartContext, useMainDataContext } from "Containers/MainContainer/MainProvider";
import { ContactAddress } from "Shared/ContactAddress/ContactAddress";
import { Fragment, ReactNode, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function Summary() {
  const [{ state }] = useMainDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (state === "notLogged") navigate("/login");
  }, [state, navigate]);

  if (state === "init") return null;

  return (
    <Container maxWidth="xl">
      <Grid container spacing={4}>
        <Grid item xs={12} md={9}>
          <Stack spacing={3}>
            <Typography variant="h5" component="h1" gutterBottom>
              Thank you for your purchase!
            </Typography>
            <ItemDivider />
            <Typography gutterBottom>Your order is complete. Below is a summary of your order.</Typography>
            <Button
              component={Link}
              to="/domains"
              variant="contained"
              color="primary"
              startIcon={<Search />}
              sx={{ width: "fit-content" }}
            >
              Search again
            </Button>
          </Stack>
          <OrderSummary />
        </Grid>
        <Grid item xs={12} md={3}>
          <Aside />
        </Grid>
      </Grid>
    </Container>
  );
}

function OrderSummary() {
  const [cart] = useCartContext();
  const {
    items,
    subtotal,
    productsPrices: {
      premiumDNS: premiumDNSPrice,
      domainPrivacy: domainPrivacyPrice,
      ssl: sslPrices,
      wordpress: wordpressPrices,
    },
  } = cart;

  return (
    <Stack sx={{ mt: "50px" }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Purchase Summary
      </Typography>
      {items.map(({ name, price, years, iCannFee, products: { premiumDNS, domainPrivacy, ssl, wordpress } }) => (
        <Grid container key={name} alignItems="center" rowSpacing={2}>
          <Grid item xs={12}>
            <ItemDivider />
          </Grid>
          <ItemRowLayout
            type="Domain Registration"
            value={name}
            price={price}
            option={<Typography>{(years > 1 ? `${years} years ` : `${years} year `) + "registration"}</Typography>}
            action={
              <Button variant="contained" fullWidth>
                Manage
              </Button>
            }
          />
          {iCannFee > 0 && <ICANNFee iCannFee={iCannFee} />}
          {premiumDNS.enabled && (
            <Fragment>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <ItemRowLayout
                type="PremiumDNS"
                value={`for ${name}`}
                price={premiumDNSPrice}
                option={<Typography>1 year subscription</Typography>}
              />
            </Fragment>
          )}
          {domainPrivacy.enabled && (
            <Fragment>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <ItemRowLayout
                type="Free Domain Privacy"
                value={`for ${name}`}
                price={domainPrivacyPrice}
                option={<Typography>1 year subscription</Typography>}
              />
            </Fragment>
          )}
          {ssl && (
            <Fragment>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <ItemRowLayout
                type="SSL"
                value={`for ${name}`}
                price={sslPrices[ssl]}
                option={<Typography>{`${ssl} ${Number(ssl) > 1 ? "years" : "year"}`}</Typography>}
              />
            </Fragment>
          )}
          {wordpress && (
            <Fragment>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <ItemRowLayout
                type="WordPress Hosting"
                value={`for ${name}`}
                price={wordpressPrices[wordpress]}
                option={<Typography>{`${wordpress} plan`}</Typography>}
              />
            </Fragment>
          )}
        </Grid>
      ))}
      <ItemDivider />
      <Grid container alignItems="center" rowSpacing={2}>
        <ItemRowLayout type="Total Charged" price={subtotal} option={null} />
      </Grid>
      <ItemDivider />
    </Stack>
  );
}

function Aside() {
  const [{ subtotal, billingAddress }] = useCartContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!billingAddress) navigate("/checkout/payment");
  }, [billingAddress, navigate]);

  if (!billingAddress) return null;

  return (
    <Stack spacing={3} component="aside">
      <Card variant="outlined">
        <CardHeader title="Customer" />
        <CardContent>
          <ContactAddress contact={billingAddress} />
        </CardContent>
      </Card>
      <Card variant="outlined">
        <CardHeader title="Payment Details" />
        <CardContent>
          <Typography variant="subtitle2" component="h2" gutterBottom>
            Payment Method
          </Typography>
          <Typography variant="body1" gutterBottom>
            Visa ending in 1234
          </Typography>
          <Stack direction="row" justifyContent="space-between" sx={{ pt: "10px" }}>
            <Typography variant="subtitle2">Total charged</Typography>
            <Typography variant="body2">
              <MoneyFormat amount={subtotal} />
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

const ItemDivider = styled(Divider)({
  marginTop: "16px",
  marginBottom: "16px",
  borderBottomWidth: "medium",
});
interface ICANNFeeProps {
  iCannFee: number;
}

function ICANNFee({ iCannFee }: ICANNFeeProps) {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body2">ICANN fee</Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            <MoneyFormat amount={iCannFee} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

interface ItemRowLayoutProps {
  type: string;
  value?: string;
  option: ReactNode;
  price: string | number;
  action?: ReactNode;
}

function ItemRowLayout({ type, value = "", option, price, action }: ItemRowLayoutProps) {
  return (
    <Fragment>
      <Grid item xs={12} sm={4}>
        <Typography variant="h6" textTransform="capitalize">
          {type}
        </Typography>
        <Typography>{value}</Typography>
      </Grid>
      <Grid item xs={12} sm={4} alignItems="center">
        {option}
      </Grid>
      <Grid item xs={12} sm={2}>
        <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
          <MoneyFormat amount={price} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={2} alignItems="center">
        {action}
      </Grid>
    </Fragment>
  );
}

export default Summary;
