import { CacheProvider, EmotionCache } from "@emotion/react";
import ClearIcon from "@mui/icons-material/Clear";
import { CssBaseline } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import { BackdropProvider } from "@nc/neoscloud-common-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AccountContainer from "Containers/AccountContainer/AccountContainer";
import Cart from "Pages/Cart/Cart";
import Contact from "Pages/Contact/Contact";
import Dashboard from "Pages/Dashboard/Dashboard";
import Domain from "Pages/Domain/Domain";
import DomainList from "Pages/DomainList/DomainList";
import Domains from "Pages/Domains/Domains";
import Home from "Pages/Home/Home";
import Login from "Pages/Login/Login";
import NotFound from "Pages/NotFound/NotFound";
import Payment from "Pages/Payment/Payment";
import Profile from "Pages/Profile/Profile";
import Review from "Pages/Review/Review";
import Summary from "Pages/Summary/Summary";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { StrictMode, createRef } from "react";
import { Route, Routes } from "react-router-dom";
import MainContainer from "../containers/MainContainer/MainContainer";
import theme from "./theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

interface AppProps {
  children: React.ReactNode;
  cache: EmotionCache;
}

export function App({ children, cache }: AppProps) {
  const notistackRef = createRef<SnackbarProvider>();
  /* istanbul ignore next */
  const onClickDismiss = (key: SnackbarKey) => () => {
    (notistackRef.current as SnackbarProvider).closeSnackbar(key);
  };

  return (
    <StrictMode>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ConfirmProvider>
              <SnackbarProvider
                maxSnack={3}
                ref={notistackRef}
                hideIconVariant
                action={(key) => (
                  <IconButton onClick={onClickDismiss(key)} aria-label="dismiss alert" component="span">
                    <ClearIcon sx={{ color: "#ffffff" }} />
                  </IconButton>
                )}
              >
                <BackdropProvider>{children}</BackdropProvider>
              </SnackbarProvider>
            </ConfirmProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </CacheProvider>
    </StrictMode>
  );
}

export function AppRoutes() {
  return (
    <MainContainer>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/domains" element={<Domains />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout/*" element={<CheckoutRoutes />} />
        <Route path="/account/*" element={<AccountRoutes />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </MainContainer>
  );
}

function CheckoutRoutes() {
  return (
    <Routes>
      <Route path="/payment" element={<Payment />} />
      <Route path="/review" element={<Review />} />
      <Route path="/summary" element={<Summary />} />
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
}

function AccountRoutes() {
  return (
    <AccountContainer>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/domains" element={<DomainList />} />
        <Route path="/domains/:domain" element={<Domain />} />
        <Route path="/domains/:domain/contact/:type" element={<Contact />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </AccountContainer>
  );
}
