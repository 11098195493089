import { ArrowDropDown, FilterAlt } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Popover,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DomainSearch from "Shared/DomainSearch/DomainSearch";
import domainListData from "TestSchemas/domain_list_data.json";
import { useConfirm } from "material-ui-confirm";
import { ChangeEvent, useState } from "react";

type Status = "Active" | "Expired" | "Alert" | "Grace";
interface Filters {
  search: string;
  status: Status[];
}

function DomainList() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;
  const [filters, setFilters] = useState<Filters>({ search: "", status: [] });
  const [statusFilter, setStatusFilter] = useState<Filters["status"]>(filters.status);

  const domains = domainListData.filter((domain) => {
    if (filters.search && !domain.name.includes(filters.search)) return false;
    if (filters.status.length > 0 && !filters.status.includes(domain.status as Status)) return false;
    return true;
  });

  return (
    <Stack spacing={3}>
      <DomainSearch placeholder="Search for your next domain" />
      <Typography variant="h5">Domain List</Typography>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          aria-describedby={id}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          startIcon={<FilterAlt />}
          endIcon={<ArrowDropDown />}
        >
          Status
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Card elevation={0}>
            <CardContent>
              <FormGroup
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const status: Status = e.target.value as Status;

                  if (e.target.checked) {
                    setStatusFilter([...filters.status, status]);
                  } else {
                    setStatusFilter(filters.status.filter((s) => s !== status));
                  }
                }}
              >
                <FormControlLabel control={<Checkbox value="Active" />} label="Active" />
                <FormControlLabel control={<Checkbox value="Expired" />} label="Expired" />
                <FormControlLabel control={<Checkbox value="Alert" />} label="Alert" />
                <FormControlLabel control={<Checkbox value="Grace" />} label="Grace" />
              </FormGroup>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => {
                  setFilters({ ...filters, status: [] });
                  setStatusFilter([]);
                  setAnchorEl(null);
                }}
                variant="text"
              >
                Clear
              </Button>
              <Button
                onClick={() => {
                  setFilters({ ...filters, status: statusFilter });
                  setAnchorEl(null);
                }}
              >
                Apply
              </Button>
            </CardActions>
          </Card>
        </Popover>
        <TextField
          id="domain-search"
          label="Search"
          variant="outlined"
          onChange={(e) => {
            setFilters({ ...filters, search: e.target.value });
          }}
        />
      </Stack>
      <Table
        aria-label="Domain List Table"
        sx={{
          display: {
            xs: "none",
            sm: "table",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Domains</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Auto-Renew</TableCell>
            <TableCell>Expiration</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {domains.length > 0 ? (
            domains.map((row) => (
              <TableRow key={row.name}>
                <TableCell sx={{ wordBreak: "break-word" }}>
                  <strong>{row.name}</strong>
                </TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>
                  <AutoRenewalSwitch name={row.name} autoRenew={row.autoRenew} />
                </TableCell>
                <TableCell>{row.expiration}</TableCell>
                <TableCell align="right">
                  <Button variant="contained" color="primary" href={`/account/domains/${row.name}/`}>
                    Manage
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <Typography variant="subtitle1">No domains found</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Stack spacing={2} sx={{ display: { sm: "none" } }}>
        {domains.length > 0 ? (
          domains.map((row) => (
            <Paper elevation={0} key={row.name} sx={{ p: "15px" }} variant="outlined">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>{row.name}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row.status}</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      <AutoRenewalSwitch name={row.name} autoRenew={row.autoRenew} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Expiration</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>{row.expiration}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      <Button variant="contained" color="primary" fullWidth>
                        Manage
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          ))
        ) : (
          <Paper sx={{ p: "15px" }}>
            <Typography variant="subtitle1">No domains found</Typography>
          </Paper>
        )}
      </Stack>
    </Stack>
  );
}

interface AutoRenewalSwitchProps {
  autoRenew: boolean;
  name: string;
}

function AutoRenewalSwitch({ autoRenew, name }: AutoRenewalSwitchProps) {
  const confirm = useConfirm();
  return (
    <Switch
      color="primary"
      checked={autoRenew}
      inputProps={{
        role: "switch",
        "aria-label": `Auto renew ${name}`,
      }}
      onChange={() => {
        if (autoRenew)
          void confirm({
            title: "Turn Off Domain Auto-Renewal",
            description:
              "You’re about to turn off auto-renewal for your domain. " +
              "Please note that this action will also disable auto-renewal for Domain Privacy service associated with the domain. " +
              "Would you like to proceed?",
            confirmationText: "Yes",
            cancellationText: "No",
          });
        else {
          void confirm({
            title: "Turn On Domain Auto-Renewal",
            description:
              "You're about to turn on auto-renewal for your domain. " +
              "Would you like to turn on auto-renewal for your domain's Domain Privacy service as well?",
            confirmationText: "Yes",
            cancellationText: "No",
          });
        }
      }}
    />
  );
}

export default DomainList;
