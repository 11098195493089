import { jsendResponseDecoder } from "Utils/jsend";
import { DecoderType, array, boolean, email, number, object, string } from "decoders";

const addressDecoder = object({
  id: number,
  firstName: string,
  lastName: string,
  companyName: string,
  jobTitle: string,
  isCompanyDomain: boolean,
  address1: string,
  address2: string,
  city: string,
  stateProvince: string,
  zipPostalCode: string,
  country: string,
  areaCode: string,
  phoneNumber: string,
  phoneExt: string,
  hasPhoneExt: boolean,
  email: email,
});
export type Address = DecoderType<typeof addressDecoder>;

const listAddress = object({
  name: string,
  id: number,
  isDefault: boolean,
});
export type ListAddress = DecoderType<typeof listAddress>;

export const getAddressResponseDecoder = jsendResponseDecoder(addressDecoder, string);
export type GetAddressResponse = DecoderType<typeof getAddressResponseDecoder>;

export const getAddressesResponseDecoder = jsendResponseDecoder(array(listAddress), string);
export type GetAddressesResponse = DecoderType<typeof getAddressesResponseDecoder>;
