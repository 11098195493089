import { Theme } from "@emotion/react";
import { Box, Container, Grid, SxProps, Typography } from "@mui/material";
import control from "Public/control-panel.png";
import dns from "Public/dns.png";
import pricing from "Public/price-tag.png";
import searching from "Public/searching.png";
import ssl from "Public/ssl-certificate.png";

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function Values() {
  return (
    <Box component="section" sx={{ display: "flex", overflow: "hidden", bgcolor: "common.white" }}>
      <Container sx={{ mt: 15, mb: 30, position: "relative" }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography color="inherit" align="center" variant="h4">
              Features & Benefits
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box component="img" src={searching} alt="searching" sx={{ height: 55 }} />
              <Typography variant="subtitle1" sx={{ my: 5, fontWeight: 700 }}>
                Easy Domain Search
              </Typography>
              <Typography variant="body1">
                {
                  "Finding the perfect domain name has never been easier. Our user-friendly domain search tool allows you to quickly check the availability of your desired domain name. Just enter your ideas, and we'll show you what's available in seconds, helping you secure your online identity effortlessly."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box component="img" src={pricing} alt="pricing" sx={{ height: 55 }} />
              <Typography variant="subtitle1" sx={{ my: 5, fontWeight: 700 }}>
                Competitive Pricing
              </Typography>
              <Typography variant="body1">
                {
                  "We believe in providing exceptional value to our customers. With our competitive pricing, you can register your dream domain without breaking the bank. Discover cost-effective domain registration options and take advantage of special offers to get the best deals on the web."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box component="img" src={control} alt="control panel" sx={{ height: 55 }} />
              <Typography variant="subtitle1" sx={{ my: 5, fontWeight: 700 }}>
                User-Friendly Control Panel
              </Typography>
              <Typography variant="body1">
                {
                  "Take full control of your domains with our intuitive and user-friendly control panel. Manage your domain settings, DNS records, and renewals with ease. Our streamlined dashboard puts the power in your hands, allowing you to make changes and updates hassle-free."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box component="img" src={ssl} alt="ssl certificate" sx={{ height: 55 }} />
              <Typography variant="subtitle1" sx={{ my: 5, fontWeight: 700 }}>
                SSL Certificates
              </Typography>
              <Typography variant="body1">
                {
                  "Security is paramount for any website. Safeguard your online presence with our SSL certificates. These digital certificates encrypt data transmission, ensuring secure connections for your visitors. Gain trust and protect sensitive information with industry-standard SSL encryption, available right here."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box component="img" src={dns} alt="dns" sx={{ height: 55 }} />
              <Typography variant="subtitle1" sx={{ my: 5, fontWeight: 700 }}>
                DNS Management
              </Typography>
              <Typography variant="body1">
                {
                  "Fine-tune your online presence with our robust DNS management tools. Easily configure DNS records, including A, CNAME, MX, and more. Customize your domain's DNS settings to suit your specific needs, whether you're hosting a website, setting up email, or directing traffic to different servers."
                }
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Values;
