import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { MainProvider } from "./MainProvider";

function MainContainer({ children }: PropsWithChildren) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <MainProvider>
        <Header />
        <Box component="main" sx={{ mt: 5, mb: 10 }}>
          {children}
        </Box>
        <Footer />
      </MainProvider>
    </Box>
  );
}

export default MainContainer;
