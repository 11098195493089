import { Either } from "@sniptt/monads/build";
import { array, Decoder, dict, string } from "decoders";
import { DecoderType, object } from "decoders/index";
import { mainDataDecoder } from "Services/api/users/interfaces";
import { jsendResponseDecoder, JsendSuccess, jsendSuccessDecoder } from "Utils/jsend";
import { eitherDecoder } from "Utils/monads";

export type FailedLoginResponse = Either<Record<string, string[]>, string>;
export const failedLoginResponseDecoder: Decoder<FailedLoginResponse> = eitherDecoder(dict(array(string)), string);

const loginData = object({
  message: string,
  data: mainDataDecoder,
});

export type LoginData = DecoderType<typeof loginData>;

export const loginResponseDecoder = jsendResponseDecoder(loginData, failedLoginResponseDecoder);
export const loginNeosAccountResponseDecoder = jsendResponseDecoder(string, failedLoginResponseDecoder);

export type LoginResponse = DecoderType<typeof loginResponseDecoder>;

export type LoginNeosAccountResponse = DecoderType<typeof loginNeosAccountResponseDecoder>;

export type LogoutResponse = JsendSuccess<string>;
export const logoutResponseDecoder: Decoder<LogoutResponse> = jsendSuccessDecoder(string);
