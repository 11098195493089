import { jsendResponseDecoder, jsendSuccessDecoder } from "Utils/jsend";
import { DecoderType, array, boolean, constant, either, number, object, string } from "decoders";

export const addCardResponseDecoder = jsendSuccessDecoder(string);

export type AddCardResponse = DecoderType<typeof addCardResponseDecoder>;

const addCardDecoder = object({
  token: string,
  saved: constant(false),
  brand: string,
  last4: string,
  exp_month: number,
  exp_year: number,
  remember: boolean,
});

export type AddCard = DecoderType<typeof addCardDecoder>;

export const savedCardDecoder = object({
  id: string,
  saved: constant(true),
  brand: string,
  last4: string,
  exp_month: number,
  exp_year: number,
  default: boolean,
});

export type SavedCard = DecoderType<typeof savedCardDecoder>;

export const paymentMethodResponseDecoder = jsendResponseDecoder(either(addCardDecoder, savedCardDecoder), string);
export type PaymentMethodResponse = DecoderType<typeof paymentMethodResponseDecoder>;

export type CartPaymentMethod = AddCard | SavedCard | null;

const paymentMethodsDecoder = array(savedCardDecoder);
export type PaymentMethods = DecoderType<typeof paymentMethodsDecoder>;

export const paymentMethodsResponseDecoder = jsendResponseDecoder(paymentMethodsDecoder, string);
export type PaymentMethodsResponse = DecoderType<typeof paymentMethodsResponseDecoder>;
