import { Dashboard as DashboardIcon, List, Person } from "@mui/icons-material";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Container,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, PropsWithChildren } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function AccountContainer({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  return (
    <Fragment>
      <Container maxWidth="xl" sx={{ mb: 5 }}>
        <Grid container spacing={6}>
          <Grid
            item
            xs={12}
            lg={2}
            sx={(theme) => ({
              display: "none",
              [theme.breakpoints.up("lg")]: {
                display: "block",
              },
            })}
          >
            <ToggleButtonGroup
              orientation="vertical"
              aria-label="Account navigation"
              value={path}
              exclusive
              onChange={(_, value: string) => {
                if (!value) return;
                navigate(`/account/${value}`);
              }}
              sx={(theme) => ({ display: "flex", position: "sticky", top: theme.spacing(12) })}
            >
              <ToggleButton value="dashboard">
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
                    <DashboardIcon />
                  </Grid>
                  <Grid item xs={8} textAlign="left">
                    Dashboard
                  </Grid>
                </Grid>
              </ToggleButton>
              <ToggleButton value="domains">
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
                    <List />
                  </Grid>
                  <Grid item xs={8} textAlign="left">
                    Domains
                  </Grid>
                </Grid>
              </ToggleButton>
              <ToggleButton value="profile">
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
                    <Person />
                  </Grid>
                  <Grid item xs={8} textAlign="left">
                    Profile
                  </Grid>
                </Grid>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} lg={10}>
            {children}
          </Grid>
        </Grid>
      </Container>
      <AppBar
        component={"div"}
        elevation={0}
        position="sticky"
        sx={(theme) => ({
          top: "auto",
          bottom: 0,
          [theme.breakpoints.up("lg")]: {
            display: "none",
          },
        })}
      >
        <BottomNavigation showLabels>
          <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} component={Link} to="/account/dashboard" />
          <BottomNavigationAction label="Domains" icon={<List />} component={Link} to="/account/domains" />
          <BottomNavigationAction label="Profile" icon={<Person />} component={Link} to="/account/profile" />
        </BottomNavigation>
      </AppBar>
    </Fragment>
  );
}

export default AccountContainer;
