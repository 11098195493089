import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { fetchDefaultConfigs } from "../config";
import { DomainSearchResponse, domainSearchResponseDecoder } from "./interfaces";

export async function searchDomain(search: string): Promise<DomainSearchResponse> {
  const response = await enhancedFetch(`${API_URL}/domains/?search=${search}`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return domainSearchResponseDecoder.verify(await response.json());
}
