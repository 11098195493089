import { Email, LocationCity, Phone } from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Copyright } from "@nc/neoscloud-common-react";
import FacebookIcon from "Icons/FacebookIcon";
import LinkedInIcon from "Icons/LinkedInIcon";
import TwitterIcon from "Icons/TwitterIcon";
import logo from "Public/NeoscloudLogo.png";

const iconLinkStyle = {
  mr: 1,
};

const iconStyle: SxProps<Theme> = {
  fill: "white",
  height: "40px",
  width: "40px",
  "&:hover": {
    bgcolor: "#424242",
  },
};

function Footer() {
  return (
    <Typography
      component="footer"
      sx={({
        palette: {
          common: { white, black },
        },
      }) => ({ display: "flex", mt: "auto", color: white, bgcolor: black })}
    >
      <Container maxWidth="xl" sx={{ mt: 8, display: "flex" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box src={logo} width="150px" component="img" alt="NeosCloud logo" />
              </Grid>
              <Grid item>
                <Typography>
                  We provide software solutions and consulting services for development, infrastructure, dev-ops,
                  project management and engineering.
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  href="https://neoscloudllc.com/about-us/"
                  color="inherit"
                  fontWeight={700}
                  underline="none"
                  target="_blank"
                  sx={{
                    "&:hover": {
                      bgcolor: "#424242",
                    },
                  }}
                >
                  About NeosCloud ➜
                </Link>
              </Grid>
              <Grid item>
                <Box component="hr" sx={{ color: "white", bgcolor: "white" }} />
              </Grid>
              <Grid item>
                <Typography variant="h6" gutterBottom color="inherit">
                  Social
                </Typography>
              </Grid>
              <Grid item sx={{ display: "flex" }}>
                <Box component="a" href="https://twitter.com/" target="_blank" sx={iconLinkStyle}>
                  <Box component={TwitterIcon} sx={iconStyle} />
                </Box>
                <Box component="a" href="https://linkedin.com/" target="_blank" sx={iconLinkStyle}>
                  <Box component={LinkedInIcon} sx={iconStyle} />
                </Box>
                <Box component="a" href="https://facebook.com/" target="_blank" sx={iconLinkStyle}>
                  <Box component={FacebookIcon} sx={iconStyle} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction="column"
              spacing={2}
              sx={(theme) => ({
                alignContent: "flex-start",
                [theme.breakpoints.up("sm")]: {
                  alignContent: "center",
                },
              })}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom color="inherit">
                  Contact
                </Typography>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={3} alignItems="center">
                  <LocationCity />
                  <Stack>
                    <Typography variant="body2" fontWeight={700}>
                      Address:
                    </Typography>
                    <Typography variant="body2">PO Box 816, West Palm Beach, FL, 33402</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={3} alignItems="center">
                  <Phone />
                  <Stack>
                    <Typography variant="body2" fontWeight={700}>
                      Phone:
                    </Typography>
                    <Typography component={Link} variant="body2" color="inherit" href="tel:+14142929000">
                      +1 (414) 292-9000
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={3} alignItems="center">
                  <Email />
                  <Stack>
                    <Typography variant="body2" fontWeight={700}>
                      Email:
                    </Typography>
                    <Typography component={Link} variant="body2" color="inherit" href="mailto:support@neosmail.com">
                      support@neosmail.com
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Stack component={Grid} item>
                <Typography variant="h6" gutterBottom color="inherit">
                  Credits
                </Typography>
                <List>
                  <ListItem disablePadding>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "caption",
                        component: Link,
                        href: "https://www.freepik.com/free-photo/cloud-storage-background-remixed-from-public-domain-by-nasa_15606925.htm",
                        title: "Freepik",
                        rel: "sponsored",
                        color: "inherit",
                        target: "_blank",
                      }}
                      color="inherit"
                    >
                      - Hero section image by rawpixel.com on Freepik
                    </ListItemText>
                  </ListItem>
                </List>
                <List>
                  <ListItem disablePadding>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "caption",
                        component: Link,
                        href: "https://www.flaticon.com/authors/freepik",
                        title: "flaticon",
                        rel: "sponsored",
                        color: "inherit",
                        target: "_blank",
                      }}
                      color="inherit"
                    >
                      - Icons created by Freepik - Flaticon
                    </ListItemText>
                  </ListItem>
                </List>
                <List>
                  <ListItem disablePadding>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "caption",
                        component: Link,
                        href: "https://www.flaticon.com/free-icons/dns",
                        title: "dns icons",
                        rel: "sponsored",
                        color: "inherit",
                        target: "_blank",
                      }}
                      color="inherit"
                    >
                      - Dns icon created by Eucalyp - Flaticon
                    </ListItemText>
                  </ListItem>
                </List>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box component="hr" sx={{ color: "white", bgcolor: "white" }} />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                pl: "24px",
                pr: "24px",
                pt: "10px",
                pb: "40px",
              }}
            >
              <Grid item xs={12} sm={3}>
                <Stack
                  sx={(theme) => ({
                    alignItems: "center",
                    marginBottom: "24px",
                    [theme.breakpoints.up("sm")]: {
                      alignContent: "flex-start",
                      flexDirection: "row",
                    },
                  })}
                >
                  <Typography
                    component={Link}
                    href="https://neoscloudllc.com/contact-us/"
                    sx={(theme) => ({ mb: 3, [theme.breakpoints.up("sm")]: { mb: 0, mr: 5 } })}
                    color="inherit"
                  >
                    Help
                  </Typography>

                  <Typography
                    component={Link}
                    href="https://neoscloudllc.com/privacy-policy/"
                    sx={(theme) => ({ mb: 3, [theme.breakpoints.up("sm")]: { mb: 0, mr: 5 } })}
                    color="inherit"
                  >
                    Privacy
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Copyright
                  sx={(theme) => ({
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                    [theme.breakpoints.up("sm")]: { textAlign: "end" },
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}

export default Footer;
